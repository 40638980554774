import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	profileStoreSuccess: [],
	profileStoreError: ['error'],
	profileStoreDone: [],

	profileShowRequest: [],
	profileShowSuccess: ['profile'],

	profileUpdateRequest: [
		'name',
		'last_name',
		'linkedin',
		'country',
		'city',
		'trajectory',
		'company_name',
		'company_role',
		'goals',
		'bio',
	],

	profileUpdatePictureRequest: ['avatar'],
	profileUpdatePictureSuccess: ['profile_picture'],
	profileUpdateCompanyRequest: ['avatarCompany'],
	profileUpdateCompanySuccess: [],

	progressOnboardingRequest: [],
	progressOnboardingSuccess: [],
	progressOnboardingStudentRequest: [],
	progressOnboardingStudentSuccess: [],
	visibleStepOnboarding: ['visibleOnboarding'],
	referralPopup: ['selected_profile'],
	profileSelectRequest: ['profile'],
	profileSelectSuccess: [],
	profileSelectError: ['message'],

	profileSetStudent: ['student_info'],

	profileSetCountry: ['countrySelect'],

	profileViewPage: ['profileViewPage'],
})

export default Creators
