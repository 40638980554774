import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	last: 0,
	numIndicacoes: [{ id: 0 }],
	friends: [{ email: '', lastname: '', name: '' }],
})

export default createReducer(INITIAL_STATE, {
	[Types.RESET_INDICACOES]: () => INITIAL_STATE,
	[Types.SEND_INDICACAO]: (state, { friends: { friends } }) =>
		state.merge({
			friends: friends.filter((_, index) =>
				state.numIndicacoes.map((d) => d.id).includes(index)
			),
		}),
	[Types.ADD_INDICACAO]: (state) =>
		state.merge({
			numIndicacoes: [...state.numIndicacoes, { id: state.last + 1 }],
			last: state.last + 1,
		}),
	[Types.REMOVE_INDICACAO]: (state, { idRemove }) =>
		state.merge({
			numIndicacoes: state.numIndicacoes.filter(({ id }) => id !== idRemove),
		}),
	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
