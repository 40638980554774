import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	paymentsMentorShowRequest: [],
	paymentsMentorShowSuccess: ['payments'],

	paymentsConsultantShowRequest: [],
	paymentsConsultantShowSuccess: ['payments'],

	paymentsAccountPixData: ['pix'],
	paymentsAccountPixRequest: ['pix_key'],
	paymentsAccountPixSuccess: [],
	paymentsAccountPixDone: [],
	paymentsAccountPixGet: [],

	paymentsCourseCreditRequest: [],
	paymentsCourseCreditSucess: [],
})

export default Creators
