import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	modalShow: ['modalTitle'],
	modalShowSuccess: ['title', 'message', 'titleButton', 'reload', 'redirect'],
	modalShowError: ['title', 'message'],
	modalShowDone: [],
	modalShowDoneOnboarding: [],
	modalShowOnboarding: ['modalTitleOnboarding'],
	modalShowNotStop: ['modalTitleNotStop'],
	modalShowDoneNotStop: [],
})

export default Creators
