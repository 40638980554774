import React from 'react'

import { Content } from './style'

function Box(props) {
	const { children } = props

	return <Content {...props}>{children}</Content>
}

export default Box
