import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as ClassCreators } from '../class/actions'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as ProfileCreators } from '../profile/actions'
import { Creators, Types } from './actions'

export function* onboardingQuestions() {
	try {
		const {
			onboarding: { data },
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)

		yield call(api.post, `class_registrations/${id}/onboardings/questions`, {
			questions: data,
		})

		yield put(Creators.onboardingQuestionsSuccess())
		yield put(ProfileCreators.progressOnboardingStudentRequest())
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* onboardingChosseMentor() {
	try {
		const {
			onboarding: {
				dataMatchMentor: { day_mentorship, hour_mentorship, mentor_id },
			},
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)

		yield call(
			api.post,
			`class_registrations/${id}/onboardings/choose/mentor`,
			{
				mentor_id,
				day: day_mentorship,
				hour: hour_mentorship,
			}
		)

		yield put(Creators.onboardingChooseMentorSuccess())
		yield put(ProfileCreators.progressOnboardingStudentRequest())
		yield put(ClassCreators.classSelectRequest(id))
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export default all([
	takeLatest(Types.ONBOARDING_QUESTIONS, onboardingQuestions),
	takeLatest(Types.ONBOARDING_CHOOSE_MENTOR, onboardingChosseMentor),
])
