import { Col, Row } from 'react-simple-flex-grid'

import styled from '@emotion/styled'

import Box from '../Box'

export const ContentHome = styled(Box)`
	@media (min-width: ${(props) => props.theme.breakpoints[2]}px) {
		grid-column: ${(props) => props.column.md || 'auto'};
	}
`

export const Content = styled.div`
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		border-bottom: 1px solid #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
`

export const Courses = styled(Row)`
	margin-top: 10px;
	width: 100%;

	@media (max-width: 400px) {
		display: block;
	}

	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		margin-top: 0;
		justify-content: flex-end;
	}
`

export const Student = styled(Col)`
	-webkit-animation: fadeIn 500ms ease-in-out 100ms both;
	animation: fadeIn 500ms ease-in-out 100ms both;

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 21px;
		width: calc(100% - 42px);
		height: 1px;
		background-color: ${(props) => props.theme.colors.lightGray};
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 100%;
		background-color: ${(props) => props.theme.colors.lightGray};
	}

	&:hover {
		.box__name {
			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 21px;
				border-radius: 10px;
				width: calc(100% - 42px);
				height: 100%;
				background-color: #daebff;
			}
		}
	}
`

export const StudentLink = styled(Row)`
	padding: 24px 5px 40px;
	margin-top: 16px;
	cursor: pointer;
`

export const Avatar = styled(Row)`
	border-radius: 100%;
	background-color: ${(props) =>
		props.bgcolor
			? props.theme.colors[props.bgcolor]
			: props.theme.colors.dark2};
	position: relative;
	width: ${(props) => props.w || 40}px;
	height: ${(props) => props.h || 40}px;
	margin-top: ${(props) => props.mt || 0}px;
	margin-bottom: ${(props) => props.mb || 0}px;

	&:first-of-type:not(:only-of-type) {
		margin-right: -12px;
	}

	&:last-of-type:not(:only-of-type) {
		border: 2px solid #ffffff;
	}
`

export const ContentCol = styled(Col)`
	max-width: 225px;
`

export const Loading = styled.div`
	height: 40px;
	padding: 0 auto;
	width: 100%;
`

export const Title = styled.p`
	font-family: 'BasisGrotesquePro';
	font-size: 16px;
	line-height: 150%;
	color: #64748b;
`
