import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	multipleSelect: [],
	singleSelect: [],
})

export default createReducer(INITIAL_STATE, {
	[Types.SET_MULTIPLE_SELECT]: (
		state,
		{ id, option, value, maxSelections }
	) => {
		const multipleSelect = JSON.parse(JSON.stringify(state.multipleSelect))
		const selected = multipleSelect.find((d) => d.id === id)

		if (selected) {
			if (selected.options.find((d) => d.id === option)) {
				selected.options = selected.options.filter((d) => d.id !== option)
			} else if (selected.options.length < Number(maxSelections)) {
				selected.options.push({ id: option, value })
			}
		} else {
			multipleSelect.push({ id, options: [{ id: option, value }] })
		}

		return state.merge({ multipleSelect })
	},
	[Types.RESET_MULTIPLE_SELECT]: (state) =>
		state.merge({ multipleSelect: INITIAL_STATE.multipleSelect }),

	[Types.SET_SINGLE_SELECT]: (state, { id, option, value }) => {
		const singleSelect = JSON.parse(JSON.stringify(state.singleSelect))
		const selected = singleSelect.find((d) => d.id === id)

		if (selected) {
			if (selected?.option?.id === option) {
				selected.option = undefined
			} else {
				selected.option = { id: option, value }
			}
		} else {
			singleSelect.push({ id, option: { id: option, value } })
		}

		return state.merge({ singleSelect })
	},
	[Types.RESET_SINGLE_SELECT]: (state) =>
		state.merge({ singleSelect: INITIAL_STATE.singleSelect }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
