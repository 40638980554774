import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	created: false,
	error: false,
	loading: false,
	profile: {
		id: undefined,
		bio: undefined,
		email: undefined,
		name: undefined,
		last_name: undefined,
		linkedin: undefined,
		profile_picture: undefined,
		selected_profile: undefined,
		status: undefined,
		coupon: undefined,
		student: {
			id: undefined,
			individual_id: undefined,
			goals: undefined,
			trajectory: undefined,
		},
		lead: {
			id: undefined,
			individual_id: undefined,
			status: undefined,
		},
		mentor: undefined,
		consultant: undefined,
		instructor: undefined,
	},
	progressOnboardingLoading: false,
	visibleStepOnboarding: false,
	student_id: undefined,
	countrySelect: undefined,
	profileViewPage: false,
})

export default createReducer(INITIAL_STATE, {
	[Types.PROFILE_STORE_SUCCESS]: (state) =>
		state.merge({ created: true, error: false }),
	[Types.PROFILE_STORE_ERROR]: (state, { error }) =>
		state.merge({ created: true, error }),
	[Types.PROFILE_STORE_DONE]: (state) =>
		state.merge({ created: false, error: false, loading: false }),

	[Types.PROFILE_UPDATE_REQUEST]: (state) => state.set('loading', true),

	[Types.PROFILE_UPDATE_PICTURE_REQUEST]: (state) => state.set('loading', true),
	[Types.PROFILE_UPDATE_PICTURE_SUCCESS]: (state, { profile_picture }) =>
		state.merge({
			loading: false,
			profile: { ...state.profile, profile_picture },
		}),

	[Types.PROFILE_UPDATE_COMPANY_REQUEST]: (state) => state.set('loading', true),
	[Types.PROFILE_UPDATE_COMPANY_SUCCESS]: (state) =>
		state.set('loading', false),

	[Types.PROFILE_SHOW_REQUEST]: (state) => state.set('loading', false),
	[Types.PROFILE_SHOW_SUCCESS]: (state, { profile }) =>
		state.merge({ profile, loading: false }),

	[Types.PROFILE_SELECT_REQUEST]: (state) => state.set('loading', true),
	[Types.PROFILE_SELECT_SUCCESS]: (state) =>
		state.merge({ created: true, loading: false }),
	[Types.PROFILE_SELECT_ERROR]: (state, { error }) =>
		state.merge({ created: true, error }),

	[Types.PROGRESS_ONBOARDING_REQUEST]: (state) =>
		state.set('progressOnboardingLoading', true),
	[Types.PROGRESS_ONBOARDING_SUCCESS]: (state) =>
		state.set('progressOnboardingLoading', false),

	[Types.PROGRESS_ONBOARDING_STUDENT_REQUEST]: (state) =>
		state.set('progressOnboardingLoading', true),
	[Types.PROGRESS_ONBOARDING_STUDENT_SUCCESS]: (state) =>
		state.set('progressOnboardingLoading', false),

	[Types.VISIBLE_STEP_ONBOARDING]: (state, { visibleStepOnboarding }) =>
		state.merge({ visibleStepOnboarding }),

	[Types.REFERRAL_POPUP]: (state, { selected_profile }) =>
		state.merge({ selected_profile }),

	[Types.PROFILE_SET_STUDENT]: (state, { student_info }) =>
		state.merge({ student_info }),

	[Types.PROFILE_SET_COUNTRY]: (state, { countrySelect }) =>
		state.merge({ countrySelect }),

	[Types.PROFILE_VIEW_PAGE]: (state, { profileViewPage }) =>
		state.merge({ profileViewPage }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
