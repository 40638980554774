import { Col, Row } from 'react-simple-flex-grid'

import styled from '@emotion/styled'

import Flex from '../Flex'

export const Content = styled.div`
	background-color: ${(props) =>
		props.theme.colors[props.bgColor] || props.theme.colors.white};
	border-radius: 8px;
	padding: ${(props) => (props.padding ? props.padding.xs : '15px')};
	max-width: ${(props) => props.mw || '100%'};
	position: relative;
	margin-top: ${(props) => props.mt || 0}px;
	margin-bottom: ${(props) => props.mb || 0}px;
	margin-right: ${(props) => props.mr && props.mr}px;
	min-width: ${(props) => (props.point ? '380px' : props.events && '89vw')};

	@media (min-width: ${(props) => props.theme.breakpoints[0]}px) {
		min-width: ${(props) => (props.point ? '380px' : props.events && '50vw')};
		margin-right: ${(props) => props.mr && 0}px;
	}

	@media (min-width: ${({ theme, point }) =>
			theme.breakpoints[point ? 3 : 1]}px) {
		min-width: ${(props) => props.events && 100}%;
	}

	@media (min-width: ${(props) => props.theme.breakpoints[2]}px) {
		background-color: ${(props) => props.bgcolors && props.bgcolors.md};
		padding: ${(props) => (props.padding ? props.padding.md : '24px')};
	}

	@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
		padding: ${(props) => (props.padding ? props.padding.lg : '24px')};
	}

	&.calendar {
		max-height: 315px;
		overflow: hidden;
		overflow-y: auto;
		margin-top: 15px;

		&::-webkit-scrollbar {
			opacity: 0;
		}

		@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
			margin-top: 0;
			height: auto;
			padding-bottom: 24px;
			max-height: unset;
		}
	}

	&.restricted {
		position: relative;
		transition: all 500ms;
		opacity: 0.2 !important;
		cursor: default !important;
	}

	&.calendarAcademic {
		padding: 18px 18px 12px;

		@media (min-width: ${(props) => props.theme.breakpoints[2]}px) {
			padding: 18px 24px 18px;
		}

		@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
			padding: 24px 36px 24px;
		}
	}

	&.classesActive {
		border: 1px solid #fb4982;
		box-shadow: none;

		&:hover {
			box-shadow: 0px 4px 20px rgba(251, 73, 130, 0.12);
		}
	}

	&.tasksModal {
		padding: 18px;

		@media (min-width: ${(props) => props.theme.breakpoints[2]}px) {
			padding: 20px 38px;
		}

		@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
			padding: 24px 44px;
		}
	}
	&.heightDisponibilidade {
		@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
			height: 100%;
		}
	}
`

export const BoxTitle = styled.p`
	font-family: ${(props) =>
		props.fw === '500' ? 'BasisGrotesquePro Medium' : 'BasisGrotesquePro'};
	font-size: ${(props) => props.fs || 14}px;
	max-width: ${(props) => `${props.max_width}px` || '100%'};
	font-weight: ${(props) => props.fw || 400};
	line-height: ${(props) => props.lineHeight || 1.5};
	color: ${(props) =>
		props.theme.colors[props.colors] || props.theme.colors.gray};
	margin-bottom: ${(props) => props.mb || 4}px;
	margin-top: ${(props) => props.mt || 0}px;
	margin-right: ${(props) => props.mr || 0}px;
	margin-left: ${(props) => props.ml || 0}px;
	padding-right: ${(props) => props.pr || 0}px !important;
	padding-left: ${(props) => props.pl || 0}px !important;
	text-align: ${(props) => props.ta && props.ta};
	cursor: ${(props) => props.cursor && props.cursor};
	width: ${(props) => props.width && props.width};

	&.spanCheckout {
		span {
			font-weight: 700;
		}
	}
	&.marginNotDefault {
		margin-right: ${(props) => props.mr || 0}px !important;
		margin-left: ${(props) => props.ml || 0}px !important;
	}
`

export const BoxContent = styled.div`
	position: relative;
`

export const BoxRestricted = styled.p`
	opacity: 1;
	background: #000000;
	width: 100%;
	max-width: 241px;
	border-radius: 4px;
	padding: 6px;
	font-family: 'BasisGrotesquePro Bold';
	font-weight: bold;
	font-size: 11px;
	line-height: 15px;
	text-align: center;
	color: #ffffff;
	position: absolute;
	top: 0;

	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		top: -50px;
		font-size: 12px;
	}

	-webkit-animation: fade-in 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: fade-in 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

	@-webkit-keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`

export const BoxSubtitle = styled.p`
	font-family: ${(props) =>
		props.fw === '500' ? 'BasisGrotesquePro Medium' : 'BasisGrotesquePro'};
	font-size: ${(props) => props.fsMin || props.fs || 16}px;
	line-height: ${(props) => props.lh || 1.25};
	color: ${(props) =>
		props.theme.colors[props.colors] || props.theme.colors.body};
	font-weight: ${(props) => props.fw || 500};
	margin-top: ${(props) => props.mt || 0}px;
	margin-bottom: ${(props) => props.mb || 0}px;
	letter-spacing: ${(props) => props.ls};
	text-align: ${(props) => props.ta && props.ta};
	cursor: ${(props) => props.cursor && props.cursor};
	padding-right: ${(props) => props.pr || 0}px;
	padding-left: ${(props) => props.pl || 0}px;
	width: 100%;
	max-width: ${(props) => props.max_width && props.max_width}px;

	@media (min-width: ${(props) => props.theme.breakpoints[0]}px) {
		font-size: ${(props) => (props.fsMin && props.fs ? props.fs : 16)}px;
	}

	&.medium {
		font-family: 'BasisGrotesquePro Medium';
		font-weight: 500;
		align-items: center;
		display: flex;
	}

	&.courseHome {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (min-width: ${(props) => props.theme.breakpoints[2]}px) {
			width: calc(100% - 30px);
		}
	}

	span {
		cursor: pointer;

		&.required {
			cursor: text;
			color: ${(props) => props.theme.colors.red2};
		}
	}
	&.mobile {
		margin-top: 8px;
		@media (min-width: ${(props) => props.theme.breakpoints[0]}px) {
			margin-top: 0;
		}
		@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
			margin-top: 8px;
		}

		@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
			margin-top: 4px;
		}
	}
`

export const BoxInfo = styled(BoxTitle)`
	font-family: 'BasisGrotesquePro Medium';
	font-weight: 500;
	margin-bottom: 0;
`

export const BoxTop = styled(Flex)`
	height: 137px;
	justify-content: space-between;
	padding: 15px 15px 13px;

	&.loading {
		margin: 0 auto;
	}

	@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
		padding: 24px 24px 13px;
	}
`

export const BoxBottom = styled(Row)`
	position: relative;
	opacity: ${(props) => props.opacity || 1};
	&.mobileBox {
		min-height: 44px;
		display: flex;
		align-items: center;
	}

	&.loading {
		height: 50.59px;
		&:before {
			display: none;
		}
	}

	&:before {
		content: '';
		width: 100%;
		position: relative;
		top: 0;
		left: 0;
		border-top: 1px solid ${(props) => props.theme.colors.lightGray};
	}
`

export const BoxBottomButton = styled(Col)`
	padding: 4px 10px;
	cursor: ${(props) => (props.locked ? 'default' : 'pointer')};

	padding: 7.8px 0 7.8px 10px;

	&:last-of-type {
		padding: 7.8px 10px 7.8px 0;

		@media (min-width: ${(props) =>
				props.theme.breakpoints[1]}px) and (max-width: ${(props) =>
				props.theme.breakpoints[2]}px) {
			padding: 0 0 7.8px 15px;
		}
	}

	&:last-of-type:not(:only-of-type) > div {
		justify-content: flex-end;
		padding-left: 0;

		@media (min-width: ${(props) =>
				props.theme.breakpoints[1]}px) and (max-width: ${(props) =>
				props.theme.breakpoints[2]}px) {
			justify-content: initial;
		}
	}
`

export const BoxBottomButton2 = styled(Col)`
	padding: 4px 10px;
	cursor: ${(props) => (props.locked ? 'default' : 'pointer')};

	padding: 7.8px 0 7.8px 15px;

	@media (min-width: ${(props) =>
			props.theme.breakpoints[1]}px) and (max-width: ${(props) =>
			props.theme.breakpoints[2]}px) {
		padding: 0 0 7.8px 15px;
	}

	&:last-of-type:not(:only-of-type) > div {
		justify-content: flex-end;
		padding-left: 0;

		@media (min-width: ${(props) =>
				props.theme.breakpoints[1]}px) and (max-width: ${(props) =>
				props.theme.breakpoints[2]}px) {
			justify-content: initial;
		}
	}
`

export const BoxBottommText = styled.p`
	font-family: 'BasisGrotesquePro Medium';
	color: ${(props) => props.theme.colors.body};
	font-size: 12px;
	line-height: 1.5;
	margin-left: 7px;
	font-weight: 500;
	margin-top: 1px;

	&.z-index {
		z-index: 2;
	}

	@media (min-width: ${(props) =>
			props.theme.breakpoints[2]}px) and (max-width: ${(props) =>
			props.theme.breakpoints[3] - 1}px) {
		margin-left: 10px;
	}
`
