import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types as TypeModal } from '../modal/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	step: 'CREDIT_CARD',
	checkoutStep: 'ADDRESS',
	addressCatched: false,
	loading: false,
	value12x: 2900,
	value1x: 2755,
	address: {
		address: '',
		city: '',
		state: '',
		country: '',
		neighborhood: '',
		zipcode: '',
		number: '',
		complementary: '',
	},
	credit_card: {
		card_number: '',
		card_name: '',
		card_validity: '',
		card_cvv: '',
		card_cpf_cnpj: '',
		option_payment: '',
		installments: '12x',
	},
	coupon: {
		name: '',
		discount_percent: '0',
		valid: false,
		error: undefined,
	},
})

const mergeObject = (A, B) => {
	const res = {}

	Object.keys({ ...A, ...B }).forEach((key) => {
		res[key] = B[key] !== undefined ? B[key] : A[key]
	})

	return res
}

export default createReducer(INITIAL_STATE, {
	[Types.SET_CHECKOUT_STEP]: (state, { step }) => state.merge(step),
	[Types.SET_CHECKOUT_FORM_STEP]: (state, { checkoutStep }) =>
		state.merge(checkoutStep),
	[Types.GET_COUPON]: (state, { coupon }) =>
		state.merge({ coupon: { name: coupon } }),
	[Types.SET_COUPON]: (state, { name, discount_percent }) =>
		state.merge({
			coupon: { name, discount_percent, valid: true },
			value12x:
				Math.ceil(
					INITIAL_STATE.value12x -
						(INITIAL_STATE.value12x * Number(discount_percent)) / 100
				) || INITIAL_STATE.value12x,
			value1x:
				Math.ceil(
					INITIAL_STATE.value12x -
						(INITIAL_STATE.value12x * Number(discount_percent)) / 100
				) || INITIAL_STATE.value1x,
			error: undefined,
		}),
	[Types.SET_COUPON_ERROR]: (state, { error }) =>
		state.merge({
			coupon: { error, name: state.coupon.name, valid: false },
			value12x: INITIAL_STATE.value12x,
			value1x: INITIAL_STATE.value1x,
		}),
	[Types.GET_ZIPCODE]: (state) => state.merge({ addressCatched: true }),
	[Types.SET_ADDRESS]: (state, address) =>
		state.merge({
			addressCatched: false,
			address: mergeObject(state.address, address),
		}),
	[Types.SET_CREDIT_CARD]: (state, credit_card) =>
		state.merge({ credit_card: mergeObject(state.credit_card, credit_card) }),
	[Types.CHECKOUT_DONE]: (state) => state.merge({ ...INITIAL_STATE }),
	[Types.SET_LOADING]: (state, { loading }) => state.merge({ loading }),
	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
	[TypeModal.MODAL_SHOW_DONE]: (state) =>
		state.step === 'COMPLETED' ? INITIAL_STATE : state,
})
