import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	courseShowRequest: ['id'],
	courseShowSuccess: ['course'],

	coursePreviewShowRequest: ['id'],
	coursePreviewShowSuccess: ['coursePreview'],

	coursesBelemniteShowRequest: [],
	coursesBelemniteShowSuccess: ['coursesBelemnite'],

	topicShowRequest: ['id'],
	topicShowSuccess: ['topic'],
	topicShowFailure: ['error'],

	topicPreviewShowRequest: ['id'],
	topicPreviewShowSuccess: ['topicPreview'],
	topicPreviewShowFailure: ['error'],

	courseMenuOpen: ['courseMenuOpen'],
	menuOpen: ['menuOpen'],

	courseRedirect: ['slug'],
})

export default Creators
