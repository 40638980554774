import styled from '@emotion/styled'

export const Box = styled.div`
	display: flex;
	flex-direction: ${(props) => props.direction || 'row'};
	align-items: ${(props) => props.align || 'flex-start'};
	justify-content: ${(props) => props.justify || 'left'};
	gap: ${(props) => props.gap};

	${(props) =>
		props.width && {
			width: props.width,
		}}

	svg {
		${(props) =>
			props.svgtp && {
				marginTop: `${props.svgtp}`,
			}}
	}
`
