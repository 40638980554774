import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Types, Creators } from './actions'

export function* showGlossary({ id }) {
	try {
		const { data } = yield call(api.get, `courses/${id}/glossary`)

		yield put(Creators.glossaryShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* showTerms({ id, terms_id }) {
	try {
		if (terms_id) {
			const { data } = yield call(api.get, `courses/${id}/glossary/${terms_id}`)

			yield put(Creators.termsShowSuccess(data))
		} else {
			yield put(Creators.termsShowDone())
		}
	} catch ({ response }) {
		//
	}
}

export default all([
	takeLatest(Types.GLOSSARY_SHOW_REQUEST, showGlossary),
	takeLatest(Types.TERMS_SHOW_REQUEST, showTerms),
])
