import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

const feedbackModel = {
	date: undefined,
	studentFeedback: undefined,
	selectedOption: undefined,
	star: 0,
	it_happened: true,
	not_attend: undefined,
	message: undefined,
	message_not: undefined,
}

const feedbacksModel = {
	feedbacks: [
		{
			type: undefined,
			id: undefined,
			date: undefined,
		},
	],
}

const feedbackTaskModel = {
	key: undefined,
	label: undefined,
	type: undefined,
	status: undefined,
	progress_id: undefined,
	class_registration_id: undefined,
}

const feedbackTasksModel = {
	class_registration_id: undefined,
	completed: undefined,
	links: [
		{
			id: undefined,
			created_at: undefined,
			link: undefined,
			progress_id: undefined,
			title: undefined,
			updated_at: undefined,
		},
	],
	progress_id: undefined,
	status: undefined,
	topic_title: undefined,
	unit_id: undefined,
}

export const INITIAL_STATE = Immutable({
	feedback: feedbackModel,
	feedbackTask: feedbackTaskModel,
	feedbackTasks: [feedbackTasksModel],
	feedbacksModal: [feedbacksModel],
	showInFeedbackModal: {
		index: 0,
		type: undefined,
		id: undefined,
		date: undefined,
	},
	loading: false,
	classRegistrationId: undefined,
})

export default createReducer(INITIAL_STATE, {
	[Types.FEEDBACK_RESET]: () => INITIAL_STATE,
	[Types.FEEDBACK_REQUEST]: (state) => state.set('loading', true),
	[Types.FEEDBACK_DONE]: (state) =>
		state.merge({
			loading: false,
			feedback: INITIAL_STATE.feedback,
			showInFeedbackModal:
				state.showInFeedbackModal.index <= state.feedbacksModal.feedbacks.length
					? {
							...state.feedbacksModal.feedbacks[
								state.showInFeedbackModal.index + 1
							],
							index: state.showInFeedbackModal.index + 1,
					  }
					: INITIAL_STATE.showInFeedbackModal,
		}),
	[Types.FEEDBACK_ERROR]: (state) => state.set('loading', false),
	[Types.FEEDBACK_SHOW]: (state, { feedback }) =>
		state.merge({ feedback: { ...state.feedback, ...feedback } }),

	[Types.FEEDBACKS_MODAL_REQUEST]: (state) => state.set('loading', true),
	[Types.FEEDBACKS_MODAL_DONE]: (state) => state.set('loading', false),
	[Types.FEEDBACKS_MODAL_SUCCESS]: (state, { feedbacksModal }) =>
		state.merge({
			feedbacksModal,
			loading: false,
			showInFeedbackModal: feedbacksModal.feedbacks.length
				? { ...feedbacksModal.feedbacks[0], index: 0 }
				: INITIAL_STATE.showInFeedbackModal,
		}),

	[Types.FEEDBACK_TASK_UPDATE]: (state, { feedbackTask }) =>
		state.merge({ feedbackTask: { ...state.feedbackTask, ...feedbackTask } }),
	[Types.FEEDBACK_TASK_DONE]: () => INITIAL_STATE,

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
	[Types.FEEDBACK_TASKS_SHOW]: (
		state,
		{ feedbackTasks, classRegistrationId }
	) => state.merge({ feedbackTasks, classRegistrationId }),
})
