import { Col } from 'react-simple-flex-grid'

import styled from '@emotion/styled'

export const EventContent = styled(Col)`
	&.pr-center {
		@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
			padding-right: 35.5px !important;
		}
	}
`
