import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	token: undefined,
	refreshToken: undefined,
	signed: false,
	error: false,
	loading: false,
	forgotLoading: false,
	forgotComplete: false,
	forgotError: false,
	resetLoading: false,
	resetComplete: false,
	resetError: false,
	createLoading: false,
	createComplete: false,
	createError: false,
	createSignupComplete: false,
	createSignupLoading: false,
	createSignupError: false,
	createSignupFreeComplete: false,
	createSignupFreeLoading: false,
	createSignupFreeError: false,
	showSignupFreeComplete: false,
	showSignupFreeLoading: false,
	showSignupFreeError: false,
	showSignupFreeData: {
		slug: undefined,
		redirect_url: undefined,
		description: undefined,
	},
	selectProfileLoading: false,
	selectProfileComplete: false,
	selectProfileError: false,
	email: '',
	stage: 'VERIFY',
	verified: false,
	magicToken: undefined,
	button: undefined,
})

export default createReducer(INITIAL_STATE, {
	[Types.SIGN_IN_SET_EMAIL]: (state, { email }) => state.merge({ email }),

	[Types.SIGN_IN_SET_STAGE]: (state, { stage }) =>
		state.merge({ error: undefined, stage }),

	[Types.SIGN_IN_VERIFY]: (state) =>
		state.merge({ loading: true, error: undefined, stage: 'VERIFY' }),
	[Types.SIGN_IN_VERIFY_SUCCESS]: (state) =>
		state.merge({ loading: false, verified: true, stage: 'LOGIN' }),
	[Types.SIGN_IN_VERIFY_FAILURE]: (state, { error }) =>
		state.merge({ loading: false, error }),

	[Types.SIGN_IN_CREATE_LINK]: (state) =>
		state.merge({ error: undefined, loading: true, button: 'LINK' }),
	[Types.SIGN_IN_CREATE_LINK_SUCCESS]: (state) =>
		state.merge({ loading: false, stage: 'LINK', button: undefined }),
	[Types.SIGN_IN_CREATE_LINK_FAILURE]: (state) =>
		state.merge({ loading: false, button: undefined }),

	[Types.SIGN_IN_MAGIC]: (state, { magicToken }) =>
		state.merge({
			error: undefined,
			loading: true,
			stage: 'MAGIC',
			magicToken,
		}),

	[Types.SIGN_IN_REQUEST]: (state) =>
		state.merge({ loading: true, button: 'PASS' }),
	[Types.SIGN_IN_DONE]: (state) =>
		state.merge({ loading: false, button: undefined }),
	[Types.SIGN_IN_SUCCESS]: (state, { token, refreshToken }) =>
		state.merge({
			loading: false,
			signed: true,
			error: false,
			button: undefined,
			token,
			refreshToken,
		}),
	[Types.SIGN_IN_FAILURE]: (state, { error }) =>
		state.merge({ loading: false, error, button: undefined }),

	[Types.SIGN_OUT_REQUEST]: () => INITIAL_STATE,

	[Types.FORGOT_REQUEST]: (state) => state.set('forgotLoading', true),
	[Types.FORGOT_SUCCESS]: (state) =>
		state.merge({
			forgotLoading: false,
			forgotError: false,
			forgotComplete: true,
		}),
	[Types.FORGOT_DONE]: (state) => state.set('forgotComplete', false),
	[Types.FORGOT_FAILURE]: (state, { error }) =>
		state.merge({ forgotLoading: false, forgotError: error }),

	[Types.RESET_REQUEST]: (state) => state.set('resetLoading', true),
	[Types.RESET_SUCCESS]: (state) =>
		state.merge({
			resetLoading: false,
			resetError: false,
			resetComplete: true,
		}),
	[Types.RESET_DONE]: (state) => state.set('resetComplete', false),
	[Types.RESET_FAILURE]: (state, { error }) =>
		state.merge({ resetLoading: false, resetError: error }),

	[Types.CREATE_REQUEST]: (state) => state.set('createLoading', true),
	[Types.CREATE_SUCCESS]: (state) =>
		state.merge({
			createLoading: false,
			createError: false,
			createComplete: true,
		}),
	[Types.CREATE_DONE]: (state) => state.set('createComplete', false),
	[Types.CREATE_FAILURE]: (state, { error }) =>
		state.merge({ createLoading: false, createError: error }),

	[Types.CREATE_SIGNUP_REQUEST]: (state) =>
		state.set('createSignupLoading', true),
	[Types.CREATE_SIGNUP_SUCCESS]: (state) =>
		state.merge({
			createSignupLoading: false,
			createSignupError: false,
			createSignupComplete: true,
		}),
	[Types.CREATE_SIGNUP_DONE]: (state) =>
		state.set('createSignupComplete', false),
	[Types.CREATE_SIGNUP_FAILURE]: (state, { error }) =>
		state.merge({
			createSignupLoading: false,
			createSignupError: error,
		}),

	[Types.CREATE_SIGNUP_FREE_REQUEST]: (state) =>
		state.set('createSignupFreeLoading', true),
	[Types.CREATE_SIGNUP_FREE_SUCCESS]: (state) =>
		state.merge({
			createSignupFreeLoading: false,
			createSignupFreeError: false,
			createSignupFreeComplete: true,
		}),
	[Types.CREATE_SIGNUP_FREE_DONE]: (state) =>
		state.set('createSignupFreeComplete', false),
	[Types.CREATE_SIGNUP_FREE_FAILURE]: (state, { error }) =>
		state.merge({
			createSignupFreeLoading: false,
			createSignupFreeError: error,
		}),

	[Types.SHOW_SIGNUP_FREE_REQUEST]: (state) =>
		state.set('showSignupFreeLoading', true),
	[Types.SHOW_SIGNUP_FREE_SUCCESS]: (state, showSignupFreeData) =>
		state.merge({
			showSignupFreeData,
			showSignupFreeLoading: false,
			showSignupFreeError: false,
			showSignupFreeComplete: true,
		}),
	[Types.SHOW_SIGNUP_FREE_DONE]: (state) =>
		state.set('showSignupFreeComplete', false),
	[Types.SHOW_SIGNUP_FREE_FAILURE]: (state, { error }) =>
		state.merge({
			showSignupFreeLoading: false,
			showSignupFreeError: error,
		}),
})
