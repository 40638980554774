import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	resetIndicacoes: [],
	addIndicacao: [],
	removeIndicacao: ['idRemove'],
	sendIndicacao: ['friends'],
})

export default Creators
