import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	glossaryShowRequest: ['id'],
	glossaryShowSuccess: ['glossary'],
	termsShowRequest: ['id', 'terms_id'],
	termsShowDone: [],
	termsShowSuccess: ['terms'],
})

export default Creators
