import dayjs from 'dayjs'
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { checkDate } from '../../../components/Calendar'
import api from '../../../utils/api'
import { Creators, Types } from './actions'

export function* showStudent() {
	try {
		const { data } = yield call(api.get, 'calendar')

		yield put(Creators.calendarStudentShowSuccess(data))

		const classSelected = yield select((state) => state.class_.classSelected)

		if (classSelected?.id) {
			yield put(Creators.calendarStudentSelect(classSelected.id))
		}
	} catch (error) {
		//
	}
}

export function* showStudentCalendarFull() {
	try {
		const { fullNavigation } = yield select((state) => state.calendar)

		const { data } = yield call(
			api.get,
			`calendar?month=${fullNavigation.month}&year=${fullNavigation.year}`
		)

		yield put(Creators.calendarFullStudentShowSuccess(data))

		const classSelected = yield select((state) => state.class_.classSelected)

		if (classSelected?.id) {
			yield put(Creators.calendarFullStudentSelect(classSelected.id))
		}
	} catch (error) {
		//
	}
}

export function* studentSelect() {
	try {
		const { studentSelectedCalendar } = yield select((state) => state.calendar)

		const numberOfDays = 15
		const findEventNextDate = studentSelectedCalendar?.calendar.find(
			(data) =>
				!dayjs(data?.date)
					.locale('pt-br')
					.isBefore(dayjs().locale('pt-br'), 'minutes')
		)

		const nextEvent = findEventNextDate?.date
			? dayjs(findEventNextDate?.date)
					.locale('pt-br')
					.format('DD [de] MMMM, YYYY, HH:mm')
			: undefined

		const events = [...Array(parseInt(numberOfDays, 10)).keys()].reduce(
			(acc, i) => {
				const currentDayNow = dayjs().locale('pt-br').add(i, 'days')
				const currentDate = dayjs().locale('pt-br')

				const event = {
					day: currentDayNow.locale('pt-br').format('DD ddd'),
					month: currentDayNow.locale('pt-br').format('MMMM, YYYY'),
					isToday: dayjs(currentDayNow)
						.locale('pt-br')
						.isSame(currentDate, 'day'),
					isFirstDateMonth: dayjs(currentDayNow)
						.locale('pt-br')
						.isSame(
							dayjs(currentDayNow).locale('pt-br').startOf('month'),
							'day'
						),
				}

				const findEventDate = studentSelectedCalendar?.calendar
					.filter((data) =>
						dayjs(data?.date).locale('pt-br').isSame(currentDayNow, 'day')
					)
					.map((data) => {
						const { mentoringInThePast, mentoringIsHappening } = checkDate(
							data,
							{
								LIVE_CLASS: 15,
								OFFICE_HOURS: 15,
								DEMO_DAY: 15,
								AWARI_TALKS: 15,
								FREE: 15,
							}[data?.type] || 5,
							{
								LIVE_CLASS: 135,
								OFFICE_HOURS: 75,
								DEMO_DAY: 135,
								AWARI_TALKS: 135,
								FREE: 135,
							}[data?.type] || 30
						)

						return {
							...event,
							id: data.id,
							title: data.title,
							dateFull: dayjs(data?.date)
								.locale('pt-br')
								.format('DD [de] MMMM, YYYY, HH:mm'),
							date: dayjs(data?.date).locale('pt-br').format('HH:mm'),
							eventInThePast: mentoringInThePast,
							eventIsHappening: mentoringIsHappening,
							link: data?.link ? data?.link : undefined,
							typeDefault: data?.type,
							externalLink: !!(
								data?.type === 'OFFICE_HOURS' ||
								data?.type === 'DEMO_DAY' ||
								data?.type === 'AWARI_TALKS' ||
								data?.type === 'MONITORING' ||
								data?.type === 'FREE'
							),
							type:
								{
									LIVE_CLASS: 'Sessão ao Vivo',
									MENTORSHIP: 'Mentoria',
									CONSULTING: 'Suporte de Carreira',
									OFFICE_HOURS: 'Office-hours',
									AWARI_TALKS: 'Awari Talks',
									DEMO_DAY: 'Live Classes',
									MONITORING: 'Monitoria',
								}[data?.type] || 'Evento',
						}
					})

				if (findEventDate.length) {
					acc.push(...findEventDate)
				} else {
					acc.push(event)
				}

				return acc
			},
			[]
		)

		yield put(Creators.calendarStudentEventsSet(events, nextEvent))
	} catch (error) {
		//
	}
}

export function* studentFullSelect() {
	try {
		const { studentFullSelectedCalendar } = yield select(
			(state) => state.calendar
		)

		if (studentFullSelectedCalendar) {
			yield put(
				Creators.calendarFullStudentEventsSet({
					class_registration_id:
						studentFullSelectedCalendar.class_registration_id,
					calendar: studentFullSelectedCalendar.calendar.map((week) => ({
						week: week.week,
						events: week.events.map((day) => ({
							day: dayjs(day.day).locale('pt-br').format('D'),
							date: day.day,
							isInThePast: dayjs(day.day)
								.locale('pt-br')
								.isBefore(dayjs().locale('pt-br'), 'day'),
							isToday: dayjs(day.day)
								.locale('pt-br')
								.isSame(dayjs().locale('pt-br'), 'day'),
							weekDay: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'][
								dayjs(day.day).locale('pt-br').day()
							],
							events: day.events.map((event) => {
								const { mentoringInThePast, mentoringIsHappening } = checkDate(
									event,
									{
										LIVE_CLASS: 15,
										OFFICE_HOURS: 15,
										DEMO_DAY: 15,
										AWARI_TALKS: 15,
										FREE: 15,
									}[event?.type] || 5,
									{
										LIVE_CLASS: 135,
										OFFICE_HOURS: 75,
										DEMO_DAY: 135,
										AWARI_TALKS: 135,
										FREE: 135,
									}[event?.type] || 30
								)

								return {
									...event,
									dateFull: dayjs(event?.date)
										.locale('pt-br')
										.format('DD [de] MMMM, YYYY, HH:mm'),
									date: dayjs(event?.date).locale('pt-br').format('HH:mm'),
									eventInThePast: mentoringInThePast,
									eventIsHappening: mentoringIsHappening,
									link: event?.link ? event?.link : undefined,
									typeDefault: event?.type,
									externalLink: !!(
										event?.type === 'OFFICE_HOURS' ||
										event?.type === 'DEMO_DAY' ||
										event?.type === 'AWARI_TALKS' ||
										event?.type === 'MONITORING' ||
										event?.type === 'FREE'
									),
									type:
										{
											LIVE_CLASS: 'Sessão ao Vivo',
											MENTORSHIP: 'Mentoria',
											CONSULTING: 'Suporte de Carreira',
											OFFICE_HOURS: 'Office-hours',
											AWARI_TALKS: 'Awari Talks',
											DEMO_DAY: 'Live Classes',
											MONITORING: 'Monitoria',
										}[event?.type] || 'Evento',
								}
							}),
						})),
					})),
				})
			)
		}
	} catch (error) {
		//
	}
}

export function* showMentor() {
	try {
		const { data } = yield call(api.get, 'calendar?limit=99')

		yield put(Creators.calendarMentorShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* showConsultant() {
	try {
		const { data } = yield call(api.get, 'calendar?limit=99')

		yield put(Creators.calendarConsultantShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* showInstructor() {
	try {
		const { data } = yield call(api.get, 'calendar?limit=99')

		yield put(Creators.calendarInstructorShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* fetchStudentPeriodically() {
	while (true) {
		yield call(showStudent)
		yield delay(1000 * 60)
	}
}

export function* fetchStudentFullPeriodically() {
	while (true) {
		yield call(showStudentCalendarFull)
		yield delay(1000 * 60)
	}
}

export default all([
	takeLatest(Types.CALENDAR_STUDENT_SHOW_REQUEST, fetchStudentPeriodically),
	takeLatest(
		Types.CALENDAR_FULL_STUDENT_SHOW_REQUEST,
		fetchStudentFullPeriodically
	),
	takeLatest(Types.CALENDAR_STUDENT_SELECT, studentSelect),
	takeLatest(Types.CALENDAR_FULL_STUDENT_SELECT, studentFullSelect),
	takeLatest(Types.CALENDAR_FULL_NAVIGATION, showStudentCalendarFull),
	takeLatest(Types.CALENDAR_MENTOR_SHOW_REQUEST, showMentor),
	takeLatest(Types.CALENDAR_CONSULTANT_SHOW_REQUEST, showConsultant),
	takeLatest(Types.CALENDAR_INSTRUCTOR_SHOW_REQUEST, showInstructor),
])
