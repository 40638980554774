import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	nextStep: ['step'],

	setGoals: ['goals'],
	setExpertises: ['expertises'],
	setCompanies: ['companies'],
	setProfile: ['profile'],
	setAvailability: ['availability'],

	onboardingQuestions: [],
	onboardingQuestionsSuccess: [],

	setMentorId: ['mentor_id'],
	setDayMentorship: ['day_mentorship'],
	setHourMentorship: ['hour_mentorship'],

	onboardingChooseMentor: [],
	onboardingChooseMentorSuccess: [],
})

export default Creators
