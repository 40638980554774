const descriptionCourses = {
	UX: {
		title: 'Intensivo de UX Design',
		subtitle:
			'Aprenda UX Design na prática, crie um portfolio e receba mentoria individual de profissionais da indústria.',
	},
	DS: {
		title: 'Intensivo de Data Science com Garantia de Emprego',
		subtitle:
			'Em nosso curso de Data Science você aprende na prática, recebe mentoria de profissionais experientes e consultoria de carreira para se tornar um cientista de dados disputado pelas empresas.',
	},
	PM: {
		title: 'Intensivo de Product Management com suporte de carreira',
		subtitle:
			'Em nosso programa intensivo de Product Management você aprende na prática, recebe mentoria de profissionais experientes e suporte de carreira para trabalhar em uma das áreas que mais cresce em empresas de tecnologia.',
	},
	UI: {
		title: 'Intensivo de UI Design',
		subtitle:
			'Com nosso curso de UI Design você aprende na prática, com aulas ao vivo e mentoria individual para se tornar um UI Designer qualificado no mercado.',
	},
	SI: {
		title: 'Intensivo de Segurança da Informação com suporte de carreira',
		subtitle:
			'Em nosso intensivo de Segurança da Informação, você aprenderá na prática por meio de projetos e receberá mentorias de profissionais de segurança de informação experientes.',
	},
	LI: {
		title: 'Intensivo de Liderança e Gestão de Pessoas com suporte de carreira',
		subtitle:
			'Em nosso intensivo de Liderança e Gestão de Pessoas, você aprenderá na prática por meio de exemplos reais e estudos de situações cotidianas, além de receber mentorias de líderes experientes, tudo o que você precisa para se tornar referência em liderança entre seus colegas.',
	},
	'UX/UI': {
		title: 'Intensivo de UX/UI Design com Garantia de Emprego',
		subtitle:
			'Em nosso curso de UX/UI Design você aprende na prática, recebe mentoria de profissionais experientes e consultoria de carreira para se tornar um UX/UI Designer disputado pelas empresas.',
	},
	'UX/FREE': {
		title: 'Curso Gratuito de UX Design',
		subtitle:
			'Aprenda a conduzir pesquisas com usuários, a criar User Flows, Personas e Job Stories e construir Wireframes ao longo de 3 aulas ao vivo.',
	},
	WD: {
		title: 'Intensivo de Desenvolvimento Web com Garantia de Emprego',
		subtitle:
			'Em nosso curso de Desenvolvimento Web você aprende na prática, recebe mentorias de profissionais experientes e consultoria de carreira para se tornar um desenvolvedor disputado pelas empresas.',
	},
	'PM/FREE': {
		title: 'Curso Gratuito de Product Management',
		subtitle:
			'Aprenda lógica de programação, JavaScript e seus principais frameworks e construa seu próprio blog ao longo de 3 aulas ao vivo.',
	},
	'WD/FREE': {
		title: 'Curso Gratuito de Desenvolvimento Web',
		subtitle:
			'Aprenda lógica de programação, JavaScript e seus principais frameworks e construa seu próprio blog ao longo de 3 aulas ao vivo.',
	},
	'DS/FREE': {
		title: 'Curso Gratuito de Ciência de Dados',
		subtitle:
			'Aprenda a criar os gráficos certos para os dados, criar apresentações e histórias com seus dados e desenvolver seus primeiros projetos em Python.',
	},
	'LG/FREE': {
		title: 'Curso Gratuito de Cultura e Gestão de Pessoas',
		subtitle:
			'Aprenda a gerir times, dar feedbacks construtivos e desenvolva suas soft skills ao longo de 3 aulas ao vivo e atividades práticas.',
	},
	'Membership/PM': {
		title: 'Product Management',
		subtitle:
			'Aprenda os fundamentos de produto e as principais técnicas utilizadas no mercado.',
	},
	'Membership/PL': {
		title: 'Product Leadership',
		subtitle:
			'Construa e lidere times de alta performance para o sucesso do produto.',
	},
	'Membership/PD': {
		title: 'Product Discovery',
		subtitle:
			'Descubra as dores reais dos seus usuários e construa produtos que impactam.',
	},
	'Membership/PMKT': {
		title: 'Product Marketing',
		subtitle:
			'Posicione seu produto de acordo com o mercado e proposta de valor.',
	},
	'Membership/DA': {
		title: 'Data Analytics',
		subtitle:
			'Analise dados de forma mais eficiente e comunique isso para outras pessoas.',
	},
	'Membership/GS': {
		title: 'Growth Strategy',
		subtitle:
			'Aprenda as principais técnicas para criar estratégias que impactam no crescimento.',
	},
	'Membership/IWD': {
		title: 'Introdução a Desenvolvimento Web',
		subtitle:
			'Conheça os princípios fundamentais para o Desenvolvimento Web e aprenda a compartilhar repositórios.',
	},
	'Membership/FER': {
		title: 'Front-End React',
		subtitle:
			'Escreva códigos responsivos e com as melhores práticas do mercado.',
	},
	'Membership/BEJ': {
		title: 'Back-End Javascript',
		subtitle:
			'Construa sistemas robustos com as tecnologias mais utilizadas do mercado.',
	},
	'Membership/RN': {
		title: 'React Native',
		subtitle:
			'Desenvolva aplicativos de forma mais eficiente com as principais tecnologias.',
	},
	'Membership/DVPS': {
		title: 'DevOps',
		subtitle:
			'Desenvolva aplicativos de forma mais eficiente com as principais tecnologias.',
	},
	'Membership/DS': {
		title: 'Data Science',
		subtitle: 'Aprenda como manipular os dados para tomar melhores decisões.',
	},
	'Membership/DE': {
		title: 'Engenharia de Dados',
		subtitle: 'Colete os dados e aprenda como aplicá-los para suas análises.',
	},
	'Membership/ML': {
		title: 'Machine Learning Avançado',
		subtitle:
			'Crie modelos preditivos e utilize o aprendizado de máquina para tomar decisões.',
	},
	'Membership/UX': {
		title: 'UX - User Experience',
		subtitle:
			'Crie soluções com usabilidade e experiência única para os usuários.',
	},
	'Membership/UI': {
		title: 'UI - Design',
		subtitle:
			'Construa interfaces funcionais e responsivas para seus produtos.',
	},
	'Membership/UXW': {
		title: 'UX Writing',
		subtitle:
			'Aprenda a importância dos textos nas interfaces e como aplicar os fundamentos.',
	},
	'Membership/UR': {
		title: 'User Research',
		subtitle:
			'Aprenda metodologias e crie pesquisas para entender melhor seus usuários.',
	},
}

export default descriptionCourses
