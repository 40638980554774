const theme = {
	breakpoints: [576, 768, 992, 1200, 1600],
	colors: {
		primary: '#1673FA',
		body: '#262A40',
		bodyLight: '#475569',
		gray: '#64748B',
		gray2: '#CBD5E1',
		gray3: '#8493AC',
		gray4: '#94A3B8',
		gray5: '#6E718A',
		gray6: '#A0A3B7',
		green: '#9CB334',
		green2: '#25E8A7',
		green3: '#1FD071',
		green4: '#BFFFDD',
		green5: '#40bf95',
		pink: '#FD477E',
		lightGray: '#F1F5F9',
		lightGray2: '#e2e8f0',
		lightGray3: '#F8FAFC',
		lightGray4: '#E8F2FF',
		lightGray5: '#f5f9ff',
		lightGray6: '#FAFCFF',
		lightGray7: '#fefdfe',
		lightGray8: '#F7F9FB',
		dark: '#070F1C',
		dark2: '#122544',
		darkBlue2: '#262A40',
		darkBlue: '#0A2D66',
		orange: '#FFBD12',
		orange2: '#FFFACC',
		red2: '#EB4755',
		red3: '#FFC2C7',
		red4: '#F7707F',
		blue: '#EFF8FF',
		blue2: '#AED4FF',
		blue3: '#DAEBFF',
		blue4: '#0088F2',
		white: '#ffff',
		purple: '#302C50',
	},
}

export default theme
