import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

const courseModel = {
	id: undefined,
	description: undefined,
	name: undefined,
	slug: undefined,
	type: undefined,
	units: [
		{
			id: undefined,
			course_id: undefined,
			description: undefined,
			title: undefined,
			slug: undefined,
			order: undefined,
			topics: [
				{
					id: undefined,
					unit_id: undefined,
					content_type: undefined,
					order: undefined,
					slug: undefined,
					time_to_read: undefined,
					title: undefined,
					task_type: undefined,
				},
			],
		},
	],
}

const topicModel = {
	id: undefined,
	unit_id: undefined,
	title: undefined,
	slug: undefined,
	body: undefined,
	order: undefined,
	task_title: undefined,
	content_type: undefined,
	time_to_read: undefined,
	free: true,
	is_active: undefined,
	task_type: undefined,
	unit: {
		id: undefined,
		title: undefined,
	},
}

export const INITIAL_STATE = Immutable({
	loading: false,
	loadingPreview: false,
	courseMenuOpen: false,
	courseRedirect: false,
	menuOpen: false,
	course: courseModel,
	coursePreview: courseModel,
	loadingTopic: false,
	loadingTopicPreview: false,
	topic: topicModel,
	topicPreview: topicModel,
	coursesBelemnite: [
		{
			id: undefined,
			description: undefined,
			slug: undefined,
			alias: undefined,
		},
	],
	topicError: false,
	topicPreviewError: false,
})

export default createReducer(INITIAL_STATE, {
	[Types.COURSE_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.COURSE_SHOW_SUCCESS]: (state, { course }) =>
		state.merge({ topic: INITIAL_STATE.topic, course, loading: false }),

	[Types.COURSE_PREVIEW_SHOW_REQUEST]: (state) =>
		state.set('loadingPreview', true),
	[Types.COURSE_PREVIEW_SHOW_SUCCESS]: (state, { coursePreview }) =>
		state.merge({
			topicPreview: INITIAL_STATE.topicPreview,
			coursePreview,
			loadingPreview: false,
		}),

	[Types.COURSE_REDIRECT]: (state, { slug }) =>
		state.merge({ courseRedirect: slug }),

	[Types.COURSES_BELEMNITE_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.COURSES_BELEMNITE_SHOW_SUCCESS]: (state, { coursesBelemnite }) =>
		state.merge({ coursesBelemnite, loading: false }),

	[Types.TOPIC_SHOW_REQUEST]: (state) => state.set('loadingTopic', true),
	[Types.TOPIC_SHOW_SUCCESS]: (state, { topic }) =>
		state.merge({ topic, loadingTopic: false }),
	[Types.TOPIC_SHOW_FAILURE]: (state, { error }) =>
		state.merge({
			loadingTopic: false,
			topicError: error,
		}),

	[Types.TOPIC_PREVIEW_SHOW_REQUEST]: (state) =>
		state.set('loadingTopicPreview', true),
	[Types.TOPIC_PREVIEW_SHOW_SUCCESS]: (state, { topicPreview }) =>
		state.merge({ topicPreview, loadingTopicPreview: false }),
	[Types.TOPIC_PREVIEW_SHOW_FAILURE]: (state, { error }) =>
		state.merge({
			loadingTopicPreview: false,
			topicPreviewError: error,
		}),

	[Types.COURSE_MENU_OPEN]: (state, { courseMenuOpen }) =>
		state.set('courseMenuOpen', courseMenuOpen),

	[Types.MENU_OPEN]: (state, { menuOpen }) => state.set('menuOpen', menuOpen),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
