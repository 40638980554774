import React, { useState } from 'react'
import { Col } from 'react-simple-flex-grid'

import { CircleLive, Happenig, LabelTop } from '../../LiveClass/style'
import {
	EventLink,
	EventTitle,
	EventDate,
	EventContentLink,
	BoxRow,
	BoxCol,
} from './style'

function Student({
	type,
	title,
	link,
	record_link,
	mentoringInThePast,
	mentoringIsHappening,
	eventDate,
}) {
	const [visibleCircle, setVisibleCircle] = useState(false)

	return (
		<EventLink
			href={link && link}
			color={(() => {
				switch (type) {
					case 'OFFICE_HOURS': {
						return 'green'
					}
					case 'AWARI_TALKS': {
						return 'darkBlue'
					}
					case 'DEMO_DAY': {
						return 'gray4'
					}
					default: {
						return 'orange'
					}
				}
			})()}
			cursor={link ? 'pointer' : 'default'}
			opacity={mentoringInThePast ? 0.5 : 1}
			target='_blank'
			data-course={type}
			className={`calendar__event ${
				!mentoringIsHappening && type !== 'FREE' && 'not-click'
			}`}
			onMouseOver={() => {
				setVisibleCircle(true)
			}}
			onMouseOut={() => {
				setVisibleCircle(false)
			}}
		>
			<Col>
				{mentoringIsHappening && visibleCircle && (
					<LabelTop>
						{(() => {
							switch (type) {
								case 'OFFICE_HOURS': {
									return 'Office-hours '
								}
								case 'AWARI_TALKS': {
									return 'Awari Talks '
								}
								case 'DEMO_DAY': {
									return 'Demo Day '
								}
								default: {
									return 'Live Classes '
								}
							}
						})()}
						acontecendo
					</LabelTop>
				)}
				<Col>
					{mentoringIsHappening ? (
						<Happenig align='middle'>
							<CircleLive mr={5} />
							<EventTitle not_witdh>
								{title &&
									(() => {
										let text = title

										switch (type) {
											case 'OFFICE_HOURS': {
												text += ' | Office-hours'
												break
											}
											case 'AWARI_TALKS': {
												text += ' | Awari Talks'
												break
											}
											case 'DEMO_DAY': {
												text += ' | Demo Day'
												break
											}
											default: {
												text += ' | Live Classes'
												break
											}
										}

										return text
									})()}
							</EventTitle>
						</Happenig>
					) : (
						<EventTitle opacity={mentoringInThePast ? 0.5 : 1}>
							{title &&
								(() => {
									let text = title

									switch (type) {
										case 'OFFICE_HOURS': {
											text += ' | Office-hours'
											break
										}
										case 'AWARI_TALKS': {
											text += ' | Awari Talks'
											break
										}
										case 'DEMO_DAY': {
											text += ' | Demo Day'
											break
										}
										default: {
											text += ' | Live Classes'
											break
										}
									}

									return text
								})()}
						</EventTitle>
					)}
				</Col>
				<BoxRow>
					<BoxCol xs={12} lg={7}>
						<EventDate opacity={mentoringInThePast ? 0.5 : 1}>
							{eventDate}
						</EventDate>
					</BoxCol>
					<BoxCol xs={12} lg={5}>
						{mentoringInThePast && (
							<EventContentLink target='_blank' href={record_link || ''}>
								Visualizar sessão
							</EventContentLink>
						)}
					</BoxCol>
				</BoxRow>
			</Col>
		</EventLink>
	)
}

export default Student
