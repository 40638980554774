import { Row } from 'react-simple-flex-grid'

import styled from '@emotion/styled'

export const LabelTop = styled.p`
	padding: 8px;
	border-radius: 6px;
	background: ${(props) => props.theme.colors.pink};
	color: ${(props) => props.theme.colors.white};
	font-family: 'BasisGrotesquePro Medium';
	font-weight: 500;
	font-size: 12px;
	line-height: 79.5%;
	position: absolute;
	top: -7px;
	left: 0px;
	z-index: 3;

	@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
		top: -17px;
		left: 10px;
	}
`

export const CircleLive = styled.span`
	display: ${(props) => props.display && props.display};
	min-width: 8px;
	height: 8px;
	border-radius: 100%;
	background-color: ${(props) => props.theme.colors.pink};
	margin-right: ${(props) => props.mr || 0}px;
`

export const Happenig = styled(Row)`
	p {
		/* margin-left: 5px; */
	}
`

export const LiveInfo = styled(Row)`
	&.happening {
		cursor: default;
	}
`

export const LinkA = styled.a``

export const ModalContent = styled(Row)`
	max-width: max-content;
	min-width: max-content;
	width: 100%;
	padding: 4px 0;
	transition: all 300ms;

	&.text-width {
		min-width: 140px;
	}

	svg {
		fill: ${(props) => props.fill && '#FB4982'};
	}

	&.hover {
		&:hover {
			border-radius: 10.4833px;
			background: #f1f5f9;
		}
	}
	&.pulser {
		padding: 5px;
		border-radius: 10.4833px;
		box-shadow: 0 0 0 0 rgba(253, 71, 126, 0.5);
		-webkit-animation: pulse 1.5s infinite;
		animation: pulse 1.5s infinite;
		&:hover {
			animation: none;
		}
		@keyframes pulse {
			0% {
				transform: scale(0.9);
			}
			70% {
				transform: scale(1);
				box-shadow: 0 0 0 5px rgba(253, 71, 126, 0);
			}
			100% {
				transform: scale(0.9);
				box-shadow: 0 0 0 0 rgba(253, 71, 126, 0);
			}
		}

		@-webkit-keyframes pulse {
			0% {
				transform: scale(0.9);
			}
			70% {
				transform: scale(1);
				box-shadow: 0 0 0 5px rgba(253, 71, 126, 0);
			}
			100% {
				transform: scale(0.9);
				box-shadow: 0 0 0 0 rgba(253, 71, 126, 0);
			}
		}
	}

	@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
		padding: 8px;
	}
`

export const DetailsEvent = styled(Row)`
	position: absolute;
	width: max-content;
	min-width: 220px;
	max-width: 230px;
	top: -24px;
	left: 50px;
	font-family: 'BasisGrotesquePro';
	font-size: 11.5px;
	line-height: 127.5%;
	display: flex;
	align-items: center;
	color: #1b1b1b;
	background: rgba(186, 197, 213, 0.23);
	border-radius: 8px;
	padding: 4px 8px;

	&:after {
		content: '';
		width: 100%;
		position: absolute;
		bottom: -5px;
		left: 35px;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid rgba(186, 197, 213, 0.23);
	}
`

export const LiveAction = styled.p`
	font-family: 'BasisGrotesquePro';
	font-size: 12px;
	line-height: 150%;
	display: flex;
	align-items: center;

	color: #64748b;

	svg {
		margin-right: 10.6px;
	}
	&.desktop {
		@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
			font-size: 10px;
		}
		@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
			font-size: 12px;
		}
	}
`

export const Classes = styled.p`
	font-family: 'BasisGrotesquePro Bold';

	font-weight: bold;
	font-size: 14px;
	line-height: 125%;
	color: #fd477e;
	margin-top: 2px;

	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		font-size: 15px;
	}

	@media (min-width: ${(props) => props.theme.breakpoints[3]}px) {
		font-size: 16px;
	}

	&.desktop {
		@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
			font-size: 12px;
		}
		@media (min-width: ${(props) => props.theme.breakpoints[2]}px) {
			font-size: 16px;
		}
	}
`
