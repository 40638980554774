import React from 'react'

function Icon(props) {
	const { width, height, name, fill, stroke } = props

	return (
		<svg width={width} height={height} fill={fill} stroke={stroke} {...props}>
			<use href={`/img/sprite.svg#${name}`} />
		</svg>
	)
}

export default Icon
