import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from './actions'

const individualModel = {
	id: undefined,
	name: undefined,
	last_name: undefined,
	profile_picture: undefined,
}

const individualPix = {
	pix_key_type: undefined,
	pix_title: undefined,
}

const mentorPaymentsModel = {
	date: undefined,
	total: undefined,
	paid: undefined,
	mentorships: [
		{
			id: undefined,
			date: undefined,
			class_registration_id: undefined,
			mentor_id: undefined,
			classRegistration: {
				id: undefined,
				student_id: undefined,
				class_id: undefined,
				student: {
					id: undefined,
					individual_id: undefined,
					individual: individualModel,
				},
				class: {
					id: undefined,
					course_id: undefined,
					course: {
						id: undefined,
						name: undefined,
						background_color: undefined,
					},
				},
			},
		},
	],
}

const cosultantPaymentsModel = {
	date: undefined,
	total: undefined,
	paid: undefined,
	consultings: [
		{
			id: undefined,
			date: undefined,
			class_registration_id: undefined,
			consultant_id: undefined,
			classRegistration: {
				id: undefined,
				student_id: undefined,
				class_id: undefined,
				student: {
					id: undefined,
					individual_id: undefined,
					individual: individualModel,
				},
				class: {
					id: undefined,
					course_id: undefined,
					course: {
						id: undefined,
						name: undefined,
						background_color: undefined,
					},
				},
			},
		},
	],
}

export const INITIAL_STATE = Immutable({
	loading: false,
	mentor: [mentorPaymentsModel],
	consultant: [cosultantPaymentsModel],
	individualPix,
})

export default createReducer(INITIAL_STATE, {
	[Types.PAYMENTS_MENTOR_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.PAYMENTS_MENTOR_SHOW_SUCCESS]: (state, { payments }) =>
		state.merge({ mentor: payments, loading: false }),

	[Types.PAYMENTS_COURSE_CREDIT_REQUEST]: (state) => state.set('loading', true),
	[Types.PAYMENTS_COURSE_CREDIT_SUCESS]: (state) =>
		state.merge('loading', false),

	[Types.PAYMENTS_CONSULTANT_SHOW_REQUEST]: (state) =>
		state.set('loading', true),
	[Types.PAYMENTS_CONSULTANT_SHOW_SUCCESS]: (state, { payments }) =>
		state.merge({ consultant: payments, loading: false }),

	[Types.PAYMENTS_ACCOUNT_PIX_DATA]: (state, { pix }) =>
		state.merge({ individualPix: pix }),
	[Types.PAYMENTS_ACCOUNT_PIX_REQUEST]: (state) => state.set('loading', true),
	[Types.PAYMENTS_ACCOUNT_PIX_SUCCESS]: (state) => state.set('loading', false),
	[Types.PAYMENTS_ACCOUNT_PIX_DONE]: (state) => state.set('loading', false),

	[Types.PAYMENTS_ACCOUNT_PIX_GET]: (state) => state.set('loading', true),
})
