import { Row, Col } from 'react-simple-flex-grid'

import styled from '@emotion/styled'

export const EventLink = styled.div`
	position: relative;
	padding: 24px 12px 24px 12px;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
	cursor: ${(props) => props.cursor};

	-webkit-animation: fadeIn 1s ease-in-out both;
	animation: fadeIn 1s ease-in-out both;

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0.5;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0.5;
		}
		to {
			opacity: 1;
		}
	}

	&:before {
		content: '';
		width: 4px;
		height: 40px;
		border-radius: 2px;
		background: ${(props) => props.theme.colors[props.color] || props.color};
		opacity: ${(props) => props.opacity || 1} !important;
		position: absolute;
		left: 0;
		top: 24px;
	}

	&:last-child {
		border-bottom: 0;
	}

	&.not-click {
		pointer-events: none;
	}
`

export const EventTitle = styled.p`
	font-family: 'BasisGrotesquePro Medium';
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	color: ${(props) => props.theme.colors.body};
	width: ${(props) => !props.not_witdh && '100%'};
	opacity: ${(props) => props.opacity || 1} !important;
`

export const BoxRow = styled(Row)``
export const BoxCol = styled(Col)``

export const EventDate = styled.p`
	font-size: 12px;
	line-height: 1.5;
	color: ${(props) => props.theme.colors.gray};
	margin-top: 4px;
	opacity: ${(props) => props.opacity || 1} !important;
`

export const EventContentLink = styled.a`
	font-size: 12px;
	line-height: 1.5;
	color: ${(props) => props.theme.colors.black};
	margin-top: 4px;
	float: right;

	@media (max-width: ${(props) => props.theme.breakpoints[3] - 1}px) {
		float: left;
	}
`
