import React from 'react'

import { Box } from './style'

function Flex(props) {
	const { children } = props

	return <Box {...props}>{children}</Box>
}

export default Flex
