import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types as TypeCourse } from '../course/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	filter: [],
	filterCourse: [],
	coursesBelemnite: [],
})

export default createReducer(INITIAL_STATE, {
	[Types.RESET_FILTER]: (state) =>
		state.merge({ filter: INITIAL_STATE.filter }),
	[Types.SET_FILTER]: (state, { filter }) =>
		state.merge({
			filter: state.filter.includes(filter)
				? state.filter.filter((item) => item !== filter)
				: [...state.filter, filter],
		}),
	[Types.RESET_FILTER_COURSE]: (state) =>
		state.merge({ filterCourse: INITIAL_STATE.filterCourse }),
	[Types.SET_FILTER_COURSE]: (state, { filterCourse }) =>
		state.merge({
			filterCourse: state.coursesBelemnite.filter(
				(item) => item.vertical === filterCourse
			),
		}),
	[TypeCourse.COURSES_BELEMNITE_SHOW_SUCCESS]: (state, { coursesBelemnite }) =>
		state.merge({ coursesBelemnite }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
