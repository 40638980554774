import 'dayjs/locale/pt-br'

import axios from 'axios'
import pagarme from 'pagarme'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as ProfileCreators } from '../profile/actions'
import { Creators, Types } from './actions'

export function* getAddress() {
	try {
		const {
			data: { address, credit_card },
		} = yield call(api.get, `/checkout`)

		yield put(Creators.setAddress(address))
		yield put(Creators.setCreditCard(credit_card))
	} catch ({ response }) {
		//
	}
}

export function* getCoupon({ coupon }) {
	try {
		const { data } = yield call(api.get, `/coupons/${coupon}`)

		yield put(Creators.setCoupon(data))
	} catch ({ response }) {
		yield put(Creators.setCouponError({ error: response.data.error }))
		//
	}
}

export function* getZipcode({ zipcode }) {
	try {
		const {
			data: {
				logradouro: address,
				localidade: city,
				uf: state,
				bairro: neighborhood,
			},
		} = yield call(axios.get, `https://viacep.com.br/ws/${zipcode}/json/`)

		yield put(
			Creators.setAddress({
				country: 'Brasil',
				zipcode,
				address: address || undefined,
				city,
				state,
				neighborhood: neighborhood || undefined,
			})
		)
	} catch ({ response }) {
		//
	}
}

export function* storeCheckout() {
	try {
		const { address, credit_card, coupon } = yield select(
			(state) => state.checkout
		)
		const card = {
			card_holder_name: credit_card.card_name,
			card_expiration_date: credit_card.card_validity,
			card_number: credit_card.card_number,
			card_cvv: credit_card.card_cvv,
		}

		const cardHash = yield new Promise((resolve, reject) => {
			pagarme.client
				.connect({
					encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTATION_KEY,
				})
				.then((client) => resolve(client.security.encrypt(card)))
				.catch((err) => reject(err.response.errors))
		})

		const resultCheckout = yield call(api.post, `/checkout`, {
			name: credit_card.card_name,
			card_hash: cardHash,
			cpf_cnpj: credit_card.card_cpf_cnpj,
			neighborhood: address.neighborhood,
			complementary: address.complementary,
			zipcode: address.zipcode,
			street: address.address,
			city: address.city,
			state: address.state,
			country: address.country,
			number: address.number,
			coupon: coupon.name,
			installments: credit_card.installments,
		})

		yield put(Creators.checkoutDone())
		yield put(Creators.setLoading(false))
		yield put(ProfileCreators.profileShowRequest())

		if (resultCheckout?.data?.status) {
			yield put(
				Creators.setCheckoutStep({
					step: resultCheckout.data.status,
				})
			)
		}
	} catch ({ response }) {
		yield put(Creators.setLoading(false))
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export default all([
	takeLatest(Types.GET_COUPON, getCoupon),
	takeLatest(Types.GET_ADDRESS, getAddress),
	takeLatest(Types.GET_ZIPCODE, getZipcode),
	takeLatest(Types.CHECKOUT_REQUEST, storeCheckout),
])
