import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	classReset: [],

	classShowRequest: [],
	classShowSuccess: ['classes'],

	classSelectRequest: ['class_id', 'force'],
	classSelectSuccess: ['classSelected'],

	classMaterialsRequest: [],
	classMaterialsSuccess: ['materials'],

	progressRequest: [],
	progressSuccess: ['progress'],
	progressDone: [],

	milestoneRequest: [],

	progressLikedRequest: ['liked'],
	progressLikedSuccess: [],

	progressCompletedRequest: ['completed', 'reload'],
	progressCompletedSuccess: [],
	progressCompletedDone: [],

	progressTaskRequest: ['link'],
	progressTaskSuccess: [],

	progressRemoveTaskRequest: ['id_task'],
	progressRemoveTaskSuccess: [],

	liveClassesRequest: [],
	liveClassesSuccess: ['liveClasses'],

	mentorshipsRequest: [],
	mentorshipsSuccess: ['mentorships'],

	consultingsRequest: [],
	consultingsSuccess: ['consultings'],

	nextConsulting: [],
	nextConsultingSuccess: [],

	onboardingCompletedRequest: [],
	onboardingSteps: ['statusOnboarding'],

	onboardingQuestionsRequest: ['questions'],

	presenceRequest: ['live_class_id'],
	presenceSuccess: [],

	certificateDownloadRequest: [],
	certificateDownloadSuccess: [],

	certificateView: [],

	taskShowRequest: [],
	taskShowDone: [],
	taskShowSuccess: ['tasks'],

	selectTopic: ['topicSlug'],

	startClass: ['infoClass'],
	courseClass: ['course'],

	nextStep: ['step'],
})

export default Creators
