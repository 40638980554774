import './index.css'
import 'react-simple-flex-grid/lib/main.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { ThemeProvider } from '@emotion/react'

import RouterApp from './router'
import { Store } from './store'
import theme from './theme'

ReactDOM.render(
	<Provider store={Store}>
		<ThemeProvider theme={theme}>
			<RouterApp />
		</ThemeProvider>
	</Provider>,
	document.getElementById('root')
)
