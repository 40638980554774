import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import courses from '../../../courses'
import api from '../../../utils/api'
import { Creators as ClassCreators } from '../class/actions'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as ProfileCreators } from '../profile/actions'
import { Creators, Types } from './actions'

export function* showMentor() {
	try {
		const { data } = yield call(api.get, 'payouts')

		yield put(Creators.paymentsMentorShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* showConsultant() {
	try {
		const { data } = yield call(api.get, 'payouts')

		yield put(Creators.paymentsConsultantShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* pixAccount({ pix_key }) {
	try {
		const {
			payments: {
				individualPix: { pix_key_type },
			},
		} = yield select((state) => state)

		yield call(api.post, `account`, {
			pix_key,
			pix_key_type,
		})
		yield put(Creators.paymentsAccountPixSuccess())
		yield put(ProfileCreators.progressOnboardingRequest())
		yield put(ModalCreators.modalShowDoneOnboarding())
		yield put(
			ModalCreators.modalShowSuccess(
				'Sucesso!',
				'PIX salvo com sucesso.',
				'Continuar',
				false,
				false
			)
		)
		yield put(Creators.paymentsAccountPixDone())
	} catch ({ response }) {
		//
		yield put(Creators.paymentsAccountPixDone())
	}
}
export function* creditCourse() {
	try {
		const {
			class_: { classInformation },
		} = yield select((state) => state)

		const { data } = yield call(
			api.post,
			`classes/${classInformation.infoClass.id}/registration`
		)

		yield put(ClassCreators.classSelectRequest(data?.class_registration_id))
		yield put(
			ModalCreators.modalShowSuccess(
				'Sucesso!',
				`Sua inscrição no curso “${
					courses[classInformation?.alias]?.title
				}” com data de início em ${
					classInformation?.infoClass?.date
				}, foi confirmada!.`,
				'Acessar curso',
				false,
				'/dashboard'
			)
		)
	} catch ({ response }) {
		//

		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* getPix() {
	try {
		const { data } = yield call(api.get, 'account')

		yield put(Creators.paymentsAccountPixData(data))
	} catch ({ response }) {
		//
	}
}

export default all([
	takeLatest(Types.PAYMENTS_MENTOR_SHOW_REQUEST, showMentor),
	takeLatest(Types.PAYMENTS_CONSULTANT_SHOW_REQUEST, showConsultant),
	takeLatest(Types.PAYMENTS_ACCOUNT_PIX_REQUEST, pixAccount),
	takeLatest(Types.PAYMENTS_COURSE_CREDIT_REQUEST, creditCourse),
	takeLatest(Types.PAYMENTS_ACCOUNT_PIX_GET, getPix),
])
