import React from 'react'

import Icon from '../../components/Icon'
import {
	StyledLegacy,
	StyledLegacyBox,
	StyledLegacyButton,
	StyledLegacyText,
	StyledLegacyTitle,
} from './style'

function Legacy() {
	return (
		<StyledLegacy>
			<Icon name='logo' fill='#1673FA' width='88' height='26' />

			<StyledLegacyBox>
				<div>
					<StyledLegacyTitle>
						Nossa plataforma mudou de endereço.
					</StyledLegacyTitle>
					<StyledLegacyText>
						Acesse agora nossa nova plataforma e confira todas as novidades!
					</StyledLegacyText>

					<StyledLegacyText>
						Qualquer dúvida entre em contato com{' '}
						<a href='mailto:meajuda@awari.com.br'>meajuda@awari.com.br</a>
					</StyledLegacyText>

					<StyledLegacyButton href='https://app.awari.com.br/' target='_blank'>
						Acessar a plataforma nova
					</StyledLegacyButton>
				</div>
			</StyledLegacyBox>
		</StyledLegacy>
	)
}

export default Legacy
