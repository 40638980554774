import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	availabilityIndexRequest: [],
	availabilityIndexSuccess: ['availabilities'],

	availabilityShowRequest: ['id'],
	availabilityShowSuccess: ['availabilities'],

	availabilityUpdateRequest: ['availability_id', 'availabilityHours'],
	availabilityUpdateSuccess: [],
})

export default Creators
