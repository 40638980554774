import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as StudentsCreators } from '../students/actions'
import { Creators, Types } from './actions'

export function* storeFeedback() {
	try {
		const {
			feedback: {
				feedback,
				feedbacksModal: feedbacksModal_,
				showInFeedbackModal,
			},
		} = yield select((state) => state)

		let dadosFeedback = {}

		if (feedback.it_happened) {
			if (feedback.not_attend) {
				dadosFeedback = { presence: false }
			} else {
				dadosFeedback = {
					rating: feedback?.star !== 0 ? feedback?.star : null,
					feedback: (() => {
						if (feedback.it_happened) {
							return feedback.message
						}
						if (feedback.message_not) {
							return feedback.message_not
						}

						return feedback.selectedOption
					})(),
					presence: feedback.it_happened,
				}
			}
		} else {
			dadosFeedback = {
				reason: (() => {
					if (feedback.it_happened) {
						return feedback.message
					}
					if (feedback.message_not) {
						return feedback.message_not
					}

					return feedback.selectedOption
				})(),
				presence: feedback.it_happened,
			}
		}

		yield call(
			api.post,
			(() => {
				switch (showInFeedbackModal.type) {
					case 'CONSULTING': {
						return `/consultings/${showInFeedbackModal.id}/feedback`
					}
					case 'LIVE_CLASS': {
						return `/liveClasses/${showInFeedbackModal.id}/feedback`
					}
					default: {
						return `/mentorships/${showInFeedbackModal.id}/feedback`
					}
				}
			})(),
			dadosFeedback
		)

		if (showInFeedbackModal.index + 1 === feedbacksModal_.feedbacks.length) {
			yield put(
				ModalCreators.modalShowSuccess(
					'Sucesso!',
					'Agradecemos o feedback.',
					undefined,
					false,
					false
				)
			)
		}

		yield put(Creators.feedbackDone())
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Esse popup irá reaparecer! Tente novamente mais tarde, ou entre em contato com time@awari.com.br e vamos te ajudar.'
			)
		)

		yield put(Creators.feedbackError())
	}
}
export function* updateFeedback({
	feedbackTask: { class_registration_id, progress_id, status },
}) {
	try {
		yield call(
			api.put,
			`class_registrations/${class_registration_id}/progress/${progress_id}`,
			{ status }
		)

		const {
			profile: { profile },
		} = yield select((state) => state)

		profile?.selected_profile === 'CONSULTANT'
			? yield put(StudentsCreators.studentsConsultantShowRequest())
			: yield put(StudentsCreators.studentsMentorShowRequest())
		// yield put(Creators.feedbackTaskDone())
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde.'
			)
		)
	}
}

export function* feedbacksModal() {
	try {
		const { data } = yield call(api.get, `feedbacks`)

		yield put(Creators.feedbacksModalSuccess(data))

		if (data?.feedbacks?.length) {
			yield put(ModalCreators.modalShow('mentoringFeedback'))
		}
	} catch ({ response }) {
		//
	}
}

export default all([
	takeLatest(Types.FEEDBACK_REQUEST, storeFeedback),
	takeLatest(Types.FEEDBACK_TASK_UPDATE, updateFeedback),
	takeLatest(Types.FEEDBACKS_MODAL_REQUEST, feedbacksModal),
])
