import 'dayjs/locale/pt-br'

import dayjs from 'dayjs'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as CalendarCreators } from '../calendar/actions'
import { Creators as CourseCreators } from '../course/actions'
import { Creators as FeedbackCreators } from '../feedback/actions'
import { Creators as FilterCreators } from '../filter/actions'
import { Creators as GlossaryCreators } from '../glossary/actions'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as ProfileCreators } from '../profile/actions'
import { Creators, Types } from './actions'

export function* show() {
	try {
		const { data } = yield call(api.get, 'classes')

		yield put(Creators.classShowSuccess(data))

		// const { selected_class_registration } = yield select(
		//   (state) => state.profile.profile.student
		// );

		// yield put(Creators.classSelectRequest(selected_class_registration));
	} catch ({ response }) {
		//
	}
}

export function* classSelected({ class_id, force }) {
	try {
		const { id } = yield select((state) => state.class_.classSelected)

		if (id === class_id && !force) {
			return
		}

		const { data } = yield call(
			api.post,
			`class_registrations/${class_id}/select`,
			{}
		)

		yield put(FeedbackCreators.feedbackReset())
		yield put(CalendarCreators.calendarStudentShowRequest())
		yield put(CalendarCreators.calendarFullStudentShowRequest())
		yield take(['CALENDAR_STUDENT_SHOW_SUCCESS'])
		yield put(Creators.classSelectSuccess(data))
		yield put(Creators.taskShowRequest())
		yield put(Creators.classMaterialsRequest())
		yield put(Creators.progressRequest())
		yield put(Creators.liveClassesRequest())
		yield put(Creators.mentorshipsRequest())
		yield put(Creators.consultingsRequest())
		yield put(FilterCreators.resetFilter())
		yield put(CalendarCreators.calendarStudentSelect(data.id))
		yield put(CalendarCreators.calendarFullStudentSelect(data.id))
		yield put(
			CalendarCreators.calendarFullNavigation({
				month: dayjs().locale('pt-br').format('M'),
				year: dayjs().locale('pt-br').format('YYYY'),
			})
		)
		yield put(CourseCreators.courseShowRequest(data.class.course_id))
		yield put(GlossaryCreators.glossaryShowRequest(data.class.course_id))
		yield put(FeedbackCreators.feedbacksModalRequest())

		if (data.certificate_show) {
			yield put(ModalCreators.modalShowOnboarding('certificateShare'))
		}
	} catch ({ response }) {
		//
	}
}

export function* showProgress() {
	try {
		const { id } = yield select((state) => state.class_.classSelected)

		const { data } = yield call(api.get, `class_registrations/${id}/progress`)

		yield put(Creators.progressSuccess(data))
	} catch (response) {
		//
	}
}

export function* classMaterials() {
	try {
		const { id } = yield select((state) => state.class_.classSelected)

		const { data } = yield call(api.get, `class_registrations/${id}/materials`)

		yield put(Creators.classMaterialsSuccess(data))
	} catch (response) {
		//
	}
}

export function* showMilestone() {
	try {
		const { id } = yield select((state) => state.class_.classSelected)

		yield call(api.post, `class_registrations/${id}/progress/milestone`, {})
	} catch (response) {
		//
	}
}

export function* storeLiked({ liked }) {
	try {
		const {
			class_: {
				classSelected: { id },
			},
			course: {
				topic: { id: topic_id },
			},
		} = yield select((state) => state)
		const { data } = yield call(
			api.post,
			`class_registrations/${id}/progress`,
			{
				topic_id,
				liked,
			}
		)

		yield put(Creators.progressLikedSuccess(data))
		yield put(Creators.progressRequest(id))
	} catch (response) {
		//
	}
}

export function* storeCompleted({ completed, reload }) {
	try {
		const {
			class_: {
				classSelected: { id },
			},
			course: {
				topic: { id: topic_id },
			},
		} = yield select((state) => state)
		const { data } = yield call(
			api.post,
			`class_registrations/${id}/progress`,
			{
				topic_id,
				completed,
			}
		)

		yield put(Creators.progressRequest())
		if (reload) {
			yield put(Creators.progressCompletedSuccess(data))
		}
	} catch (response) {
		//
	}
}

export function* storeTask({ link }) {
	try {
		const {
			class_: {
				classSelected: { id },
			},
			course: {
				topic: { id: topic_id },
			},
		} = yield select((state) => state)
		const { data } = yield call(
			api.post,
			`class_registrations/${id}/progress`,
			{
				topic_id,
				link,
				completed: true,
			}
		)

		yield put(Creators.progressTaskSuccess(data))
		yield put(Creators.progressRequest(id))
		yield put(Creators.taskShowRequest())
	} catch (response) {
		//
	}
}

export function* removeTask({ id_task }) {
	try {
		const {
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)
		const { data } = yield call(
			api.delete,
			`class_registrations/${id}/progress/${id_task}`
		)

		yield put(Creators.progressRemoveTaskSuccess(data))
		yield put(Creators.progressRequest(id))
	} catch (response) {
		//
	}
}

export function* onboardingCompleted() {
	try {
		const {
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)

		yield call(api.post, `class_registrations/${id}/onboardings/completed`)
		yield put(ProfileCreators.profileShowRequest())
		yield put(ModalCreators.modalShowDone())
	} catch (response) {
		//
	}
}

export function* showLiveClasses() {
	try {
		const { id } = yield select((state) => state.class_.classSelected)

		const { data } = yield call(
			api.get,
			`class_registrations/${id}/liveClasses`
		)

		yield put(Creators.liveClassesSuccess(data))
	} catch (response) {
		//
	}
}

export function* showMentorships() {
	try {
		const { id } = yield select((state) => state.class_.classSelected)

		const { data } = yield call(
			api.get,
			`class_registrations/${id}/mentorships`
		)

		yield put(Creators.mentorshipsSuccess(data))
	} catch (response) {
		//
	}
}

export function* showConsultings() {
	try {
		const { id } = yield select((state) => state.class_.classSelected)

		const { data } = yield call(
			api.get,
			`class_registrations/${id}/consultings`
		)

		yield put(Creators.consultingsSuccess(data))
	} catch (response) {
		//
	}
}

export function* onboardingQuestionsCompleted({ questions }) {
	try {
		const {
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)

		yield call(api.post, `class_registrations/${id}/onboardings/questions`, {
			questions,
		})

		yield put(ProfileCreators.profileShowRequest())
		yield put(ModalCreators.modalShowDone())
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* presenceRequest({ live_class_id }) {
	try {
		const {
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)

		yield call(api.post, `class_registrations/${id}/presences`, {
			live_class_id,
		})

		yield put(Creators.presenceSuccess())
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* certificateView() {
	try {
		const {
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)

		yield call(api.post, `certificate/flag`, {
			class_registration_id: id,
		})
		yield put(ModalCreators.modalShowDoneOnboarding())
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* certificateDownloadRequest() {
	try {
		const {
			class_: {
				classSelected: { id },
			},
		} = yield select((state) => state)

		yield call(api.post, `class_registrations/${id}/certificated`, {})

		yield put(Creators.certificateDownloadSuccess())
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}
export function* showTask() {
	try {
		const { id } = yield select((state) => state.class_.classSelected)
		const { data } = yield call(api.get, `class_registrations/${id}/tasks`)

		yield put(Creators.taskShowSuccess(data))
		yield put(Creators.taskShowDone())
	} catch (response) {
		//
	}
}

export default all([
	takeLatest(Types.CLASS_SHOW_REQUEST, show),
	takeLatest(Types.CLASS_SELECT_REQUEST, classSelected),
	takeLatest(Types.CLASS_MATERIALS_REQUEST, classMaterials),
	takeLatest(Types.PROGRESS_REQUEST, showProgress),
	takeLatest(Types.MILESTONE_REQUEST, showMilestone),
	takeLatest(Types.PROGRESS_LIKED_REQUEST, storeLiked),
	takeLatest(Types.PROGRESS_COMPLETED_REQUEST, storeCompleted),
	takeLatest(Types.PROGRESS_TASK_REQUEST, storeTask),
	takeLatest(Types.PROGRESS_REMOVE_TASK_REQUEST, removeTask),
	takeLatest(Types.LIVE_CLASSES_REQUEST, showLiveClasses),
	takeLatest(Types.MENTORSHIPS_REQUEST, showMentorships),
	takeLatest(Types.CONSULTINGS_REQUEST, showConsultings),
	takeLatest(Types.ONBOARDING_COMPLETED_REQUEST, onboardingCompleted),
	takeLatest(Types.ONBOARDING_QUESTIONS_REQUEST, onboardingQuestionsCompleted),
	takeLatest(Types.PRESENCE_REQUEST, presenceRequest),
	takeLatest(Types.CERTIFICATE_DOWNLOAD_REQUEST, certificateDownloadRequest),
	takeLatest(Types.CERTIFICATE_VIEW, certificateView),
	takeLatest(Types.TASK_SHOW_REQUEST, showTask),
])
