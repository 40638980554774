import axios from 'axios'
import useSWR from 'swr'

const api = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
})

export function useFetch(url) {
	const { data, error, mutate } = useSWR(url, async (url_) => {
		const response = await api.get(url_)

		return response.data
	})

	return { data, error, mutate }
}

export async function handleRegistration(class_id, id, liked, link, completed) {
	const data = {
		topic_id: id && id,
		link: link && link,
		liked: liked && liked,
		completed: completed && completed,
	}

	if (class_id) {
		return api.post(`/class_registrations/${class_id}/progress`, data)
	}

	return ''
}

export default api
