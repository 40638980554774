import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	created: false,
	loading: false,
	modalTitle: undefined,
	modalTitleOnboarding: undefined,
	modalTitleNotStop: undefined,
	returnRequest: {
		type: undefined,
		title: undefined,
		message: undefined,
		titleButton: undefined,
		reload: undefined,
		redirect: undefined,
	},
})

export default createReducer(INITIAL_STATE, {
	[Types.MODAL_SHOW]: (state, { modalTitle }) =>
		state.modalTitle === 'alert' ? state : state.merge({ modalTitle }),
	[Types.MODAL_SHOW_ONBOARDING]: (state, { modalTitleOnboarding }) =>
		state.merge({ modalTitleOnboarding }),
	[Types.MODAL_SHOW_NOT_STOP]: (state, { modalTitleNotStop }) =>
		state.merge({ modalTitleNotStop }),
	[Types.MODAL_SHOW_SUCCESS]: (
		state,
		{ title, message, titleButton, reload, redirect }
	) =>
		state.merge({
			created: true,
			modalTitle: 'alert',
			returnRequest: {
				type: 'success',
				title,
				message,
				titleButton,
				reload,
				redirect,
			},
		}),
	[Types.MODAL_SHOW_ERROR]: (state, { title, message }) =>
		state.merge({
			created: true,
			modalTitle: 'alert',
			returnRequest: { type: 'error', title, message },
		}),
	[Types.MODAL_SHOW_DONE]: () => {
		document.getElementsByTagName('body')[0].style.overflow = 'auto'

		return INITIAL_STATE
	},
	[Types.MODAL_SHOW_DONE_ONBOARDING]: (state) => {
		document.getElementsByTagName('body')[0].style.overflow = 'auto'

		return state.set('modalTitleOnboarding', undefined)
	},
	[Types.MODAL_SHOW_DONE_NOT_STOP]: (state) => {
		document.getElementsByTagName('body')[0].style.overflow = 'auto'

		return state.set('modalTitleNotStop', undefined)
	},

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
