import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	resetFilter: [],
	setFilter: ['filter'],

	resetFilterCourse: [],
	setFilterCourse: ['filterCourse'],
})

export default Creators
