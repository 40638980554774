import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as FeedbackCreators } from '../feedback/actions'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as ProfileCreators } from '../profile/actions'
import { Creators, Types } from './actions'

export function* showMentor() {
	try {
		const { data } = yield call(api.get, 'students')

		yield put(FeedbackCreators.feedbackReset())
		yield put(Creators.studentsMentorShowSuccess(data))
		yield put(FeedbackCreators.feedbacksModalRequest())
	} catch ({ response }) {
		//
	}
}

export function* showConsultant() {
	try {
		const { data } = yield call(api.get, 'students')

		yield put(FeedbackCreators.feedbackReset())
		yield put(Creators.studentsConsultantShowSuccess(data))
		yield put(FeedbackCreators.feedbacksModalRequest())
	} catch ({ response }) {
		//
	}
}

export function* showAplication(data) {
	try {
		yield call(api.post, 'apply', data)

		yield put(Creators.studentAplicationShowSucess())
		yield put(ProfileCreators.profileShowRequest())
	} catch (err) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* showInstructor() {
	try {
		const { data } = yield call(api.get, 'students')

		yield put(Creators.studentsInstructorShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export default all([
	takeLatest(Types.STUDENTS_MENTOR_SHOW_REQUEST, showMentor),
	takeLatest(Types.STUDENTS_CONSULTANT_SHOW_REQUEST, showConsultant),
	takeLatest(Types.STUDENTS_INSTRUCTOR_SHOW_REQUEST, showInstructor),
	takeLatest(Types.STUDENT_APLICATION_SHOW_REQUEST, showAplication),
])
