import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as ProfileCreator } from '../profile/actions'
import { Creators as AuthCreator, Types } from './actions'

export function* loadToken() {
	const token = localStorage.getItem('token')
	const refreshToken = localStorage.getItem('refreshToken')

	if (!token || !refreshToken) {
		window.zE('webWidget', 'hide')

		return
	}

	yield put(AuthCreator.signInSuccess({ token, refreshToken }))
}

export function* verify() {
	try {
		const { email } = yield select((state) => state.auth)

		yield call(api.post, 'login/verify', { email })

		yield put(AuthCreator.signInVerifySuccess())
	} catch ({ response }) {
		yield put(AuthCreator.signInVerifyFailure(response.data.message))
	}
}

export function* createLink() {
	try {
		const { email } = yield select((state) => state.auth)

		yield call(api.post, 'login/link', { email })

		yield put(AuthCreator.signInCreateLinkSuccess())
	} catch ({ response }) {
		yield put(AuthCreator.signInCreateLinkFailure(response.data.message))
	}
}

export function* signInMagic({ magicToken }) {
	try {
		const { data } = yield call(api.post, 'login', { magicToken })

		yield put(AuthCreator.signInSuccess(data))
	} catch ({ response }) {
		yield put(AuthCreator.signInFailure(response.data.message))
	}
}

export function* signIn({ email, password }) {
	try {
		const { data } = yield call(api.post, 'login', { email, password })

		if (data.redirect) {
			window.location.href = `https://app.awari.com.br?token=${data.token}&role=${data.role}`
		} else {
			yield put(AuthCreator.signInSuccess(data))
		}

		yield put(AuthCreator.signInDone())
	} catch ({ response }) {
		yield put(AuthCreator.signInFailure(response.data.message))
		yield put(AuthCreator.signInDone())
	}
}

export function* signInSuccess({ token, refreshToken }) {
	api.defaults.headers.Authorization = `Bearer ${token}`

	try {
		localStorage.setItem('token', token)
		localStorage.setItem('refreshToken', refreshToken)

		window.zE('webWidget', 'show')

		yield put(ProfileCreator.profileShowRequest())
		yield put(ModalCreators.modalShowDone())
	} catch ({ response }) {
		yield put(AuthCreator.signOutRequest())
	}
}

export function signOut() {
	api.defaults.headers.Authorization = ''

	localStorage.removeItem('token')
	localStorage.removeItem('refreshToken')
	localStorage.removeItem('dateModalFeedback')

	window.zE('webWidget', 'hide')
}

export function* forgot({ email }) {
	try {
		yield call(api.post, 'password/forgot', { email })

		yield put(AuthCreator.forgotSuccess())
	} catch ({ response }) {
		yield put(AuthCreator.forgotFailure(response.data.message))
	}
}

export function* reset({ token, password, password_confirmation }) {
	try {
		const { data } = yield call(api.post, 'password/reset', {
			token,
			password,
			password_confirmation,
		})

		yield put(AuthCreator.resetSuccess())
		yield put(AuthCreator.signInSuccess(data))
	} catch ({ response }) {
		yield put(AuthCreator.resetFailure(response.data.message))
	}
}

export function* create({ token, password, password_confirmation }) {
	try {
		yield call(api.post, 'password/create', {
			token,
			password,
			password_confirmation,
		})

		yield put(AuthCreator.createSuccess())
	} catch ({ response }) {
		yield put(AuthCreator.createFailure(response.data.message))
	}
}

export function* createSignup({ token, password, password_confirmation }) {
	try {
		yield call(api.post, 'password/create', {
			token,
			password,
			password_confirmation,
		})

		yield put(AuthCreator.createSignupSuccess())
	} catch ({ response }) {
		yield put(AuthCreator.createSignupFailure(response.data.message))
	}
}

export function* createSignupFree({
	name,
	last_name,
	email,
	password,
	password_confirmation,
	telephone,
	class_slug,
}) {
	try {
		const { data } = yield call(api.post, 'free/signup', {
			name,
			last_name,
			email,
			password,
			password_confirmation,
			telephone,
			class_slug,
		})

		yield put(AuthCreator.createSignupFreeSuccess())
		if (data.token) {
			yield put(AuthCreator.signInSuccess(data))
		}
	} catch ({ response }) {
		yield put(AuthCreator.createSignupFreeFailure(response.data.message))
	}
}

export function* showSignupFree({ url }) {
	try {
		const { data } = yield call(api.get, `free/signup/${url}`)

		yield put(AuthCreator.showSignupFreeSuccess(data))
	} catch ({ response }) {
		yield put(AuthCreator.showSignupFreeFailure(response.data.message))
	}
}

export default all([
	fork(loadToken),
	takeLatest(Types.SIGN_IN_VERIFY, verify),
	takeLatest(Types.SIGN_IN_CREATE_LINK, createLink),
	takeLatest(Types.SIGN_IN_MAGIC, signInMagic),
	takeLatest(Types.SIGN_IN_REQUEST, signIn),
	takeLatest(Types.SIGN_IN_SUCCESS, signInSuccess),
	takeLatest(Types.SIGN_OUT_REQUEST, signOut),
	takeLatest(Types.FORGOT_REQUEST, forgot),
	takeLatest(Types.RESET_REQUEST, reset),
	takeLatest(Types.CREATE_REQUEST, create),
	takeLatest(Types.CREATE_SIGNUP_REQUEST, createSignup),
	takeLatest(Types.CREATE_SIGNUP_FREE_REQUEST, createSignupFree),
	takeLatest(Types.SHOW_SIGNUP_FREE_REQUEST, showSignupFree),
])
