import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

const classRegistrationModel = {
	id: undefined,
	class_id: undefined,
	student_id: undefined,
	mentor_id: undefined,
	consultant_id: undefined,
	status: undefined,
	onboarding_completed: undefined,
	onboarding_status: undefined,
	daily_class_token: undefined,
	daily_mentorship_token: undefined,
	daily_mentorship_room: undefined,
	daily_consulting_token: undefined,
	daily_consulting_room: undefined,
	observation: undefined,
	calendar_mentorship_id: undefined,
	calendar_consulting_id: undefined,
	certificated_at: undefined,
	progress_milestone_show: undefined,
	progress_milestone_stage: undefined,
	completed_at: undefined,
	created_at: undefined,
	updated_at: undefined,
	class: {
		id: undefined,
		course_id: undefined,
		instructor_id: undefined,
		name: undefined,
		slug: undefined,
		cohort: undefined,
		number_mentorships: undefined,
		number_consultings: undefined,
		number_live_classes: undefined,
		demo_day: undefined,
		daily_room: undefined,
		drive_id: undefined,
		calendar_id: undefined,
		is_active: undefined,
		created_at: undefined,
		updated_at: undefined,
	},
}

const progressModel = {
	id: undefined,
	name: undefined,
	slug: undefined,
	next_unit_id: undefined,
	next_topic_id: undefined,
	progress: 0,
	units: [
		{
			id: undefined,
			course_id: undefined,
			description: undefined,
			slug: undefined,
			order: undefined,
			progress: 0,
			topics: [
				{
					id: undefined,
					unit_id: undefined,
					title: undefined,
					slug: undefined,
					order: undefined,
					task_type: undefined,
					progress: {
						id: undefined,
						topic_id: undefined,
						completed: undefined,
						links: [
							{
								id: undefined,
								progress_id: undefined,
								link: undefined,
								title: undefined,
							},
						],
					},
				},
			],
		},
	],
}

const liveClassesModel = [
	{
		id: undefined,
		class_id: undefined,
		instructor_id: undefined,
		class_week_id: undefined,
		date: undefined,
		daily_id: undefined,
		started_at: undefined,
		drive_id: undefined,
		completed: undefined,
		presence: undefined,
		studentFeedback: {
			id: undefined,
			live_class_id: undefined,
			class_registration_id: undefined,
			rating: undefined,
			presence: undefined,
			feedback: undefined,
		},
	},
]

const mentorshipsModel = [
	{
		id: undefined,
		class_registration_id: undefined,
		mentor_id: undefined,
		date: undefined,
		daily_id: undefined,
		started_at: undefined,
		payable: undefined,
		studentFeedback: undefined,
		completed: undefined,
		presence: undefined,
	},
]

const consultingsModel = [
	{
		id: undefined,
		class_registration_id: undefined,
		consultant_id: undefined,
		date: undefined,
		daily_id: undefined,
		started_at: undefined,
		payable: undefined,
		studentFeedback: undefined,
		completed: undefined,
		presence: undefined,
	},
]

const statusOnboarding = {
	progress: undefined,
	levelOnboarding: undefined,
}

const tasks = [
	{
		id: undefined,
		unit_id: undefined,
		title: undefined,
		slug: undefined,
		task_type: undefined,
		progress: {
			topicSlug: undefined,
			id: undefined,
			topic_id: undefined,
			links: [
				{
					id: undefined,
					progress_id: undefined,
					link: undefined,
				},
			],
		},
	},
]

const classesModel = [
	{
		id: undefined,
		class_id: undefined,
		class: {
			id: undefined,
			name: undefined,
			course_id: undefined,
			course: { id: undefined, description: undefined, alias: undefined },
		},
	},
]

const materialsModel = [
	{
		id: undefined,
		date: undefined,
		type: undefined,
		title: undefined,
		record_link: undefined,
	},
]

export const INITIAL_STATE = Immutable({
	loading: false,
	classSelected: classRegistrationModel,
	classSelectedLoading: false,
	next_topic_slug: undefined,
	classes: classesModel,
	progressLoading: false,
	progressDone: false,
	progress: progressModel,
	liveClassesLoading: false,
	liveClasses: liveClassesModel,
	mentorshipsLoading: false,
	mentorships: mentorshipsModel,
	consultingsLoading: false,
	consultings: consultingsModel,
	progressLikedLoading: false,
	progressCompletedLoading: false,
	progressCompletedDone: false,
	progressTaskLoading: false,
	progressRemoveTaskLoading: false,
	taskLoading: false,
	tasks,
	presenceLoading: false,
	certificateDownloadLoading: false,
	statusOnboarding,
	materials: materialsModel,
	materialsLoading: false,
	topicSlug: undefined,
	classInformation: undefined,
})

export default createReducer(INITIAL_STATE, {
	[Types.CLASS_RESET]: () => INITIAL_STATE,

	[Types.CLASS_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.CLASS_SHOW_SUCCESS]: (state, { classes }) =>
		state.merge({ classes, loading: false }),

	[Types.CLASS_SELECT_REQUEST]: (state) =>
		state.merge({
			classSelectedLoading: true,
		}),
	[Types.CLASS_SELECT_SUCCESS]: (state, { classSelected }) =>
		state.merge({
			classSelected,
			classSelectedLoading: false,
		}),

	[Types.CLASS_MATERIALS_REQUEST]: (state) =>
		state.set('materialsLoading', true),
	[Types.CLASS_MATERIALS_SUCCESS]: (state, { materials }) =>
		state.merge({ materials, materialsLoading: false }),

	[Types.PROGRESS_REQUEST]: (state) => state.set('progressLoading', true),
	[Types.PROGRESS_SUCCESS]: (state, { progress }) =>
		state.merge({
			progress,
			next_topic_slug: progress.units
				.find((d) => d.id === progress.next_unit_id)
				.topics.find((d) => d.id === progress.next_topic_id).slug,
			progressLoading: false,
			progressDone: true,
		}),
	[Types.PROGRESS_DONE]: (state) => state.merge({ progressDone: false }),

	[Types.PROGRESS_LIKED_REQUEST]: (state) =>
		state.set('progressLikedLoading', true),
	[Types.PROGRESS_LIKED_SUCCESS]: (state) =>
		state.merge({ progressLikedLoading: false }),

	[Types.PROGRESS_COMPLETED_REQUEST]: (state) =>
		state.set('progressCompletedLoading', true),
	[Types.PROGRESS_COMPLETED_SUCCESS]: (state) =>
		state.merge({
			progressCompletedLoading: false,
			progressCompletedDone: true,
		}),
	[Types.PROGRESS_COMPLETED_DONE]: (state) =>
		state.merge({ progressCompletedDone: false }),

	[Types.PROGRESS_TASK_REQUEST]: (state) =>
		state.set('progressTaskLoading', true),
	[Types.PROGRESS_TASK_SUCCESS]: (state) =>
		state.merge({ progressTaskLoading: false }),

	[Types.PROGRESS_REMOVE_TASK_REQUEST]: (state) =>
		state.set('progressRemoveTaskLoading', true),
	[Types.PROGRESS_REMOVE_TASK_SUCCESS]: (state) =>
		state.merge({ progressRemoveTaskLoading: false }),

	[Types.LIVE_CLASSES_REQUEST]: (state) =>
		state.set('liveClassesLoading', true),
	[Types.LIVE_CLASSES_SUCCESS]: (state, { liveClasses }) =>
		state.merge({ liveClasses, liveClassesLoading: false }),

	[Types.MENTORSHIPS_REQUEST]: (state) => state.set('mentorshipsLoading', true),
	[Types.MENTORSHIPS_SUCCESS]: (state, { mentorships }) =>
		state.merge({ mentorships, mentorshipsLoading: false }),

	[Types.CONSULTINGS_REQUEST]: (state) => state.set('consultingsLoading', true),
	[Types.CONSULTINGS_SUCCESS]: (state, { consultings }) =>
		state.merge({ consultings, consultingsLoading: false }),

	[Types.PRESENCE_REQUEST]: (state) => state.set('presenceLoading', true),
	[Types.PRESENCE_SUCCESS]: (state) => state.set('presenceLoading', false),

	[Types.CERTIFICATE_DOWNLOAD_REQUEST]: (state) =>
		state.set('certificateDownloadLoading', true),
	[Types.CERTIFICATE_DOWNLOAD_SUCCESS]: (state) =>
		state.set('certificateDownloadLoading', false),

	[Types.TASK_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.TASK_SHOW_SUCCESS]: (state, { tasks: tasks_ }) =>
		state.merge({ tasks: tasks_, loading: false }),

	[Types.ONBOARDING_STEPS]: (state, { statusOnboarding: statusOnboarding_ }) =>
		state.merge({ statusOnboarding: statusOnboarding_ }),

	[Types.SELECT_TOPIC]: (state, { topicSlug }) => state.merge({ topicSlug }),

	[Types.START_CLASS]: (state, { infoClass }) =>
		state.merge({ classInformation: { ...state.classInformation, infoClass } }),
	[Types.COURSE_CLASS]: (state, { course }) =>
		state.merge({
			classInformation: {
				...state.classInformation,
				alias: course.alias,
				class: course.classes,
			},
		}),
	[Types.NEXT_STEP]: (state, { step }) => state.merge({ step }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
