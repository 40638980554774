import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import availability from './availability/sagas'
import calendar from './calendar/sagas'
import checkout from './checkout/sagas'
import class_ from './class/sagas'
import contact from './contact/sagas'
import course from './course/sagas'
import feedback from './feedback/sagas'
import glossary from './glossary/sagas'
import leads from './leads/sagas'
import onboarding from './onboarding/sagas'
import payments from './payments/sagas'
import profile from './profile/sagas'
import reschedule from './reschedule/sagas'
import students from './students/sagas'

export default function* rootSaga() {
	yield all([
		auth,
		class_,
		calendar,
		course,
		profile,
		students,
		feedback,
		reschedule,
		availability,
		payments,
		contact,
		glossary,
		onboarding,
		checkout,
		leads,
	])
}
