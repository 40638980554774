import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as ModalCreators } from '../modal/actions'
import { Types } from './actions'

export function* updateMentorship({
	mentorship_id,
	time,
	date,
	this_and_following,
}) {
	try {
		yield call(api.put, `/mentorships/${mentorship_id}`, {
			time,
			date,
			this_and_following,
		})

		yield put(
			ModalCreators.modalShowSuccess(
				'Sucesso!',
				'Mentoria remarcada.',
				undefined,
				true,
				false
			)
		)
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}
export function* updateConsulting({
	consulting_id,
	time,
	date,
	this_and_following,
}) {
	try {
		yield call(api.put, `/consultings/${consulting_id}`, {
			time,
			date,
			this_and_following,
		})

		yield put(
			ModalCreators.modalShowSuccess(
				'Sucesso!',
				'Consultoria remarcada.',
				undefined,
				true,
				false
			)
		)
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com time@awari.com.br.'
			)
		)
	}
}

export default all([
	takeLatest(Types.RESCHEDULE_MENTORSHIP_REQUEST, updateMentorship),
	takeLatest(Types.RESCHEDULE_CONSULTING_REQUEST, updateConsulting),
])
