import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	signInSetEmail: ['email'],

	signInSetStage: ['stage'],

	signInVerify: [],
	signInVerifySuccess: [],
	signInVerifyFailure: ['error'],

	signInCreateLink: [],
	signInCreateLinkSuccess: [],
	signInCreateLinkFailure: ['error'],

	signInMagic: ['magicToken'],

	signInRequest: ['email', 'password'],
	signInDone: [],
	signInSuccess: { token: '', refreshToken: '' },
	signInFailure: ['error'],

	createSignupRequest: ['token', 'password', 'password_confirmation'],
	createSignupSuccess: [],
	createSignupDone: [],
	createSignupFailure: ['error'],

	signOutRequest: [],

	forgotRequest: ['email'],
	forgotSuccess: [],
	forgotDone: [],
	forgotFailure: ['error'],

	resetRequest: ['token', 'password', 'password_confirmation'],
	resetSuccess: [],
	resetDone: [],
	resetFailure: ['error'],

	createRequest: ['token', 'password', 'password_confirmation'],
	createSuccess: [],
	createDone: [],
	createFailure: ['error'],

	createSignupFreeRequest: [
		'name',
		'last_name',
		'email',
		'password',
		'password_confirmation',
		'telephone',
		'class_slug',
	],
	createSignupFreeSuccess: [],
	createSignupFreeDone: [],
	createSignupFreeFailure: ['error'],

	showSignupFreeRequest: { url: '' },
	showSignupFreeSuccess: {
		slug: undefined,
		redirect_url: undefined,
		description: undefined,
	},
	showSignupFreeDone: [],
	showSignupFreeFailure: ['error'],
})

export default Creators
