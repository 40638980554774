import dayjs from 'dayjs'
import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import 'dayjs/locale/pt-br'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

const individualModel = {
	id: undefined,
	name: undefined,
	last_name: undefined,
	email: undefined,
	profile_picture: undefined,
}

const studentModel = {
	class_registration_id: undefined,
	mentor: {
		id: undefined,
		individual_id: undefined,
		individual: individualModel,
	},
	consultant: {
		id: undefined,
		individual_id: undefined,
		individual: individualModel,
	},
	instructor: {
		id: undefined,
		individual_id: undefined,
		individual: individualModel,
	},
	number_mentorships: undefined,
	number_consultings: undefined,
	number_live_classes: undefined,
	daily: {
		daily_class_token: undefined,
		daily_class_room: undefined,
		daily_mentorship_token: undefined,
		daily_mentorship_room: undefined,
		daily_consulting_token: undefined,
		daily_consulting_room: undefined,
	},
	first_mentorship: undefined,
	first_consulting: undefined,
	first_live_class: undefined,
	completed_mentorships: undefined,
	completed_consultings: undefined,
	completed_live_classes: undefined,
	calendar: [
		{
			date: undefined,
			type: undefined,
		},
	],
}

const mentorModel = {
	id: undefined,
	class_registration_id: undefined,
	mentor_id: undefined,
	date: undefined,
	completed: undefined,
	daily_id: undefined,
	duration: undefined,
	started_at: undefined,
	payable: undefined,
	classRegistration: {
		id: undefined,
		student_id: undefined,
		class_id: undefined,
		daily_mentorship_room: undefined,
		student: {
			id: undefined,
			individual_id: undefined,
			individual: individualModel,
		},
		class: {
			id: undefined,
			course_id: undefined,
			course: {
				id: undefined,
				name: undefined,
				background: undefined,
			},
		},
		mentorToken: {
			id: undefined,
			class_registration_id: undefined,
			mentor_id: undefined,
			daily_token: undefined,
		},
	},
	type: undefined,
}

const consultantModel = {
	id: undefined,
	class_registration_id: undefined,
	consultant_id: undefined,
	date: undefined,
	completed: undefined,
	daily_id: undefined,
	duration: undefined,
	started_at: undefined,
	payable: undefined,
	classRegistration: {
		id: undefined,
		student_id: undefined,
		class_id: undefined,
		daily_consulting_room: undefined,
		student: {
			id: undefined,
			individual_id: undefined,
			individual: individualModel,
		},
		class: {
			id: undefined,
			course_id: undefined,
			course: {
				id: undefined,
				name: undefined,
				background: undefined,
			},
		},
		consultantToken: {
			id: undefined,
			class_registration_id: undefined,
			consultant_id: undefined,
			daily_token: undefined,
		},
	},
	type: undefined,
}

const calendarFullModel = {
	class_registration_id: undefined,
	calendar: [{}],
}

export const INITIAL_STATE = Immutable({
	loading: false,
	student: [studentModel],
	calendarFull: [calendarFullModel],
	studentSelectedCalendar: studentModel,
	studentFullSelectedCalendar: [calendarFullModel],
	studentEvents: [],
	studentFullEvents: [],
	studentNextEvent: undefined,
	mentor: [mentorModel],
	consultant: [consultantModel],
	instructor: [{}],
	nextClass: 'Aguardando horário',
	nextMentorship: 'Aguardando horário',
	nextConsulting: 'Aguardando horário',
	fullNavigation: {
		month: dayjs().locale('pt-br').format('M'),
		year: dayjs().locale('pt-br').format('YYYY'),
	},
})

export default createReducer(INITIAL_STATE, {
	[Types.CALENDAR_STUDENT_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.CALENDAR_STUDENT_SHOW_SUCCESS]: (state, { calendar }) =>
		state.merge({ student: calendar, loading: false }),
	[Types.CALENDAR_FULL_STUDENT_SHOW_REQUEST]: (state) =>
		state.set('loading', true),
	[Types.CALENDAR_FULL_STUDENT_SHOW_SUCCESS]: (state, { calendarFull }) =>
		state.merge({ calendarFull, loading: false }),
	[Types.CALENDAR_STUDENT_SELECT]: (state, { class_registration_id }) =>
		state.merge({
			studentSelectedCalendar: state.student.find(
				(d) => d.class_registration_id === class_registration_id
			),
		}),
	[Types.CALENDAR_FULL_STUDENT_SELECT]: (state, { class_registration_id }) =>
		state.merge({
			studentFullSelectedCalendar: state.calendarFull.find(
				(d) => d.class_registration_id === class_registration_id
			),
		}),
	[Types.CALENDAR_STUDENT_EVENTS_SET]: (state, { events, nextEvent }) =>
		state.merge({
			studentEvents: events,
			studentNextEvent: nextEvent,
		}),
	[Types.CALENDAR_FULL_STUDENT_EVENTS_SET]: (state, { events }) =>
		state.merge({
			studentFullSelectedCalendar: events,
		}),

	[Types.CALENDAR_MENTOR_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.CALENDAR_MENTOR_SHOW_SUCCESS]: (state, { calendar }) =>
		state.merge({ mentor: calendar, loading: false }),

	[Types.CALENDAR_CONSULTANT_SHOW_REQUEST]: (state) =>
		state.set('loading', true),
	[Types.CALENDAR_CONSULTANT_SHOW_SUCCESS]: (state, { calendar }) =>
		state.merge({ consultant: calendar, loading: false }),

	[Types.CALENDAR_INSTRUCTOR_SHOW_REQUEST]: (state) =>
		state.set('loading', true),
	[Types.CALENDAR_INSTRUCTOR_SHOW_SUCCESS]: (state, { calendar }) =>
		state.merge({ instructor: calendar, loading: false }),

	[Types.NEXT_CLASS_SHOW]: (state, { nextClass }) => state.merge({ nextClass }),
	[Types.NEXT_MENTORSHIP_SHOW]: (state, { nextMentorship }) =>
		state.merge({ nextMentorship }),
	[Types.NEXT_CONSULTING_SHOW]: (state, { nextConsulting }) =>
		state.merge({ nextConsulting }),

	[Types.CALENDAR_FULL_NAVIGATION]: (state, { fullNavigation }) =>
		state.merge({ fullNavigation }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
