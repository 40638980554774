import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	studentsMentorShowRequest: [],
	studentsMentorShowSuccess: ['students'],

	studentsConsultantShowRequest: [],
	studentsConsultantShowSuccess: ['students'],

	studentsInstructorShowRequest: [],
	studentsInstructorShowSuccess: ['students'],

	studentAplicationShowRequest: ['dataSubmit'],
	studentAplicationShowSucess: [],

	StudentSetHowMet: ['howMet'],

	modalCourses: ['status'],
	modalProfile: ['status'],
	modalProfileContent: ['status'],

	downloadSocialShare: ['name'],
})

export default Creators
