import ContentEditable from 'react-contenteditable'
import { Row } from 'react-simple-flex-grid'

import styled from '@emotion/styled'

export const Content = styled(Row)`
	padding-top: ${(props) => props.pt.xs || 40}px;
	flex-wrap: nowrap;

	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		padding-top: ${(props) => props.pt.md || 40}px;
	}
`

export const Avatar = styled(Row)`
	border-radius: 100%;
	background-color: ${(props) => props.theme.colors.dark2};
	position: relative;
	cursor: pointer;
	width: ${(props) => props.width || 72}px;
	height: ${(props) => props.height || 72}px;
	margin-bottom: 20px;
	opacity: ${(props) => props.opacity && '0.7'};
	pointer-events: ${(props) => props.opacity && 'none'};

	${(props) => props.bg && { background: props.bg }}

	&.backgroundNone {
		background-color: transparent !important;
		z-index: 1;
	}

	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		width: 96px;
		height: 96px;
		margin-bottom: 0;
	}
`

export const AvatarImg = styled.img`
	border-radius: 100%;
	height: 96px;
	width: 96px;
	display: ${(props) => props.display || 'flex'};

	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		height: 100%;
		width: 100%;
	}
`

export const Greeting = styled.p`
	font-family: 'BasisGrotesquePro Bold';
	font-size: 20px;
	line-height: 1.33;
	letter-spacing: -0.02em;
	color: ${(props) => props.theme.colors.purple};
	margin-bottom: 8px;

	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		font-size: 24px;
	}
	@media (min-width: ${(props) => props.theme.breakpoints[1]}px) {
		font-size: 24px;
	}
`

export const Bio = styled(ContentEditable)`
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -0.02em;
	color: ${(props) => props.theme.colors.gray};
	outline: none;
	max-width: 400px;

	@media (min-width: ${(props) => props.theme.breakpoints[0]}px) {
		font-size: 16px;
		line-height: 1.5;
	}

	&.focused {
		border-bottom: 1px solid #64748b;
		max-width: fit-content;
	}
`

export const Pencil = styled(Row)`
	background-color: ${(props) => props.theme.colors.lightGray};
	border: 2px solid #ffffff;
	border-radius: 100px;
	width: 24px;
	height: 24px;
	border-radius: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 10;
`

export const InputFile = styled.input`
	display: none;
`

export const Animation = styled.div`
	display: none;
	@media (min-width: ${(props) => props.theme.breakpoints[0]}px) {
		display: block;
		animation: shake 2.5s infinite alternate ease-in-out;
		transform-origin: 70% 70%;

		@keyframes shake {
			0% {
				transform: rotate(0deg);
			}
			10% {
				transform: rotate(14deg);
			}
			20% {
				transform: rotate(-8deg);
			}
			30% {
				transform: rotate(14deg);
			}
			40% {
				transform: rotate(-4deg);
			}
			50% {
				transform: rotate(10deg);
			}
			60% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(0deg);
			}
		}
	}
`
