import { Link } from 'react-router-dom'
import { Row, Col } from 'react-simple-flex-grid'

import styled from '@emotion/styled'

export const Subtitle = styled.p`
	font-family: 'BasisGrotesquePro Medium';
	font-weight: 500;
	font-size: 11.5px;
	line-height: 1.67;
	color: ${(props) => props.theme.colors.body};
	display: flex;
	align-items: center;
	margin-top: ${(props) => props.mt || 16}px;
	justify-content: ${(props) => props.justify && props.justify};
	cursor: pointer;

	&:before {
		content: '';
		width: 4px;
		height: 4px;
		border-radius: 2px;
		background: ${(props) => props.theme.colors[props.color] || props.color};
		display: block;
		margin-right: 8px;
	}

	&.inactive {
		opacity: 0.5;
	}
`

export const Event = styled(Link)`
	position: relative;
	padding: 24px 0 24px 12px;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
	cursor: ${(props) => props.cursor};
	opacity: ${(props) => props.opacity || 1} !important;

	-webkit-animation: fadeIn 1s ease-in-out both;
	animation: fadeIn 1s ease-in-out both;

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0.5;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0.5;
		}
		to {
			opacity: 1;
		}
	}

	&:before {
		content: '';
		width: 4px;
		height: 40px;
		border-radius: 2px;
		background: ${(props) => props.theme.colors[props.color] || props.color};
		position: absolute;
		left: 0;
		top: 24px;
	}

	&:last-child {
		border-bottom: 0;
	}

	&.not-click {
		pointer-events: none;
	}
`

export const EventLink = styled.a`
	position: relative;
	padding: 24px 0 24px 12px;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
	cursor: ${(props) => props.cursor};
	opacity: ${(props) => props.opacity || 1} !important;
	pointer-events: ${(props) => props.event || 'auto'};
	-webkit-animation: fadeIn 1s ease-in-out both;
	animation: fadeIn 1s ease-in-out both;

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0.5;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0.5;
		}
		to {
			opacity: 1;
		}
	}

	&:before {
		content: '';
		width: 4px;
		height: 40px;
		border-radius: 2px;
		background: ${(props) => props.theme.colors[props.color] || props.color};
		position: absolute;
		left: 0;
		top: 24px;
	}

	&:last-child {
		border-bottom: 0;
	}

	&.not-click {
		pointer-events: none;
	}
`

export const EventTitle = styled.p`
	font-family: 'BasisGrotesquePro Medium';
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	color: ${(props) => props.theme.colors.body};
	width: ${(props) => !props.not_witdh && '100%'};
`

export const EventDate = styled.p`
	font-size: 12px;
	line-height: 1.5;
	color: ${(props) => props.theme.colors.gray};
	margin-top: 4px;
`

export const WatchClass = styled(Row)`
	margin-top: 8px;
`

export const Chevron = styled.span`
	margin-left: 13px;
	margin-top: 7.5px;

	svg {
		transition: all 500ms;
		stroke: ${(props) => props.theme.colors.primary};
	}

	&.open {
		svg {
			transform: rotate(180deg);
			stroke: ${(props) => props.theme.colors.primary};
		}
	}
`

export const IconContainer = styled.span`
	margin-right: 12px;
`

export const EmptyEvent = styled.span`
	position: static;
	width: 205px;
	height: 20px;
	left: 0px;
	top: 0px;

	font-family: 'BasisGrotesquePro';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;

	display: flex;
	align-items: center;

	color: #cbd5e1;

	flex: none;
	order: 0;
	flex-grow: 0;
	margin-top: 16px;
`

export const ColContent = styled(Col)`
	width: ${(props) => props.width || 'auto'};
`
