import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	contactStoreRequest: {
		name: undefined,
		email: undefined,
		comment: undefined,
		course: undefined,
		subject: undefined,
		profile: undefined,
	},
	contactStoreSuccess: [],
})

export default Creators
