import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	loading: false,

	glossary: [
		{
			id: undefined,
			course_id: undefined,
			term: [undefined],
		},
	],

	terms: [
		{
			id: undefined,
			term: undefined,
			is_translated: undefined,
			concept_translate: undefined,
			concept: undefined,
			created_at: undefined,
			updated_at: undefined,
		},
	],
})

export default createReducer(INITIAL_STATE, {
	[Types.GLOSSARY_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.GLOSSARY_SHOW_SUCCESS]: (state, { glossary }) =>
		state.merge({ glossary, loading: false }),

	[Types.TERMS_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.TERMS_SHOW_SUCCESS]: (state, { terms }) =>
		state.merge({ terms, loading: false }),
	[Types.TERMS_SHOW_DONE]: (state) => state.set('loading', false),
})
