import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	rescheduleMentorshipRequest: [
		'mentorship_id',
		'time',
		'date',
		'this_and_following',
	],
	rescheduleConsultingRequest: [
		'consulting_id',
		'time',
		'date',
		'this_and_following',
	],
})

export default Creators
