import 'dayjs/locale/pt-br'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-simple-flex-grid'

import dayjs from 'dayjs'

import colors from '../../colorsmonths'
import { Creators as FilterCreators } from '../../store/modules/filter/actions'
import { Subtitle } from '../Calendar/style'
import { EventContent } from './style'

function Filter({ element, mt, items, type }) {
	const {
		profile: {
			profile: { selected_profile },
		},
		filter: { filter },
	} = useSelector((state) => state)
	const dispatch = useDispatch()

	const [courses, setCourses] = useState([])
	const [months, setMonths] = useState([])

	const itemInstructor = [
		{
			id: 'LIVE_CLASS',
			name: 'Live Classes',
		},
		{
			id: 'OFFICE_HOURS',
			name: 'Office-hours',
		},
		{
			id: 'DEMO_DAY',
			name: 'Demo day',
		},
		{
			id: 'MONITORING',
			name: 'Monitoria',
		},
		{
			id: 'AWARI_TALKS',
			name: 'Awari talks',
		},
	]

	selected_profile === 'INSTRUCTOR'
		? items &&
		  items.forEach((item) => {
				if (
					!months.find(
						(m) => m.month === dayjs(item.date).locale('pt-br').format('MMMM')
					)
				) {
					setMonths([
						...months,
						{
							color: colors[dayjs(item.date).locale('pt-br').format('MMMM')],
							month: dayjs(item.date).locale('pt-br').format('MMMM'),
						},
					])
				}
		  })
		: selected_profile !== 'STUDENT' &&
		  items &&
		  items.forEach((item) => {
				if (!courses.find((c) => c.id === item.class.course_id)) {
					setCourses([...courses, item.class.course])
				}
		  })

	function handleCourse(id) {
		dispatch(FilterCreators.setFilter(id))
	}

	useEffect(() => {
		const $student = Array.from(document.getElementsByClassName(element))

		if (filter.length) {
			$student.forEach((el) => {
				el.style.display = 'none'
			})

			filter.map((item) =>
				document
					.querySelectorAll(`[data-course="${item}"], [data-course2="${item}"]`)
					.forEach((el) => {
						if (el.className.includes(element)) {
							if (el.style.display === 'block') {
								el.style.display = 'none'
							} else {
								el.style.display = 'block'
							}
						}
					})
			)
		} else {
			$student.forEach((el) => {
				el.style.display = 'block'
			})
		}
	}, [filter, element])

	if (!items || !items[0]) return ''

	if (selected_profile === 'STUDENT') {
		return (
			<Row gutter={45}>
				{items.map((item, idx) => (
					<EventContent
						key={idx}
						onClick={() => handleCourse(item.id)}
						className={item.id === 'CONSULTING' && 'pr-center'}
					>
						<Subtitle
							color={(() => {
								switch (item.id) {
									case 'LIVE_CLASS': {
										return 'primary'
									}
									case 'MENTORSHIP': {
										return 'pink'
									}
									case 'CONSULTING': {
										return 'orange'
									}
									case 'OFFICE_HOURS': {
										return 'green'
									}
									case 'AWARI_TALKS': {
										return 'darkBlue'
									}
									case 'DEMO_DAY': {
										return 'gray4'
									}
									default: {
										return 'orange'
									}
								}
							})()}
							mt={mt}
							className={
								filter.includes(item.id) || !filter.length ? null : 'inactive'
							}
						>
							{item.name}
						</Subtitle>
					</EventContent>
				))}
			</Row>
		)
	}

	if (selected_profile === 'INSTRUCTOR') {
		return (
			<>
				<Row gutter={35}>
					{itemInstructor.map((item, idx) => (
						<Col key={idx} onClick={() => handleCourse(item.id)}>
							<Subtitle
								color={(() => {
									switch (item.id) {
										case 'LIVE_CLASS': {
											return 'primary'
										}
										case 'MENTORSHIP': {
											return 'pink'
										}
										case 'CONSULTING': {
											return 'orange'
										}
										case 'OFFICE_HOURS': {
											return 'green'
										}
										case 'AWARI_TALKS': {
											return 'darkBlue'
										}
										default: {
											return 'gray4'
										}
									}
								})()}
								mt={mt}
								className={
									filter.includes(item.id) || !filter.length ? null : 'inactive'
								}
							>
								{item.name}
							</Subtitle>
						</Col>
					))}
				</Row>
				<Row gutter={type === 'event' ? '1' : '20'}>
					{months.map((item, key) => (
						<Col
							key={key}
							sm={type === 'big' ? null : 6}
							onClick={() => handleCourse(item.month)}
						>
							<Subtitle
								color={item.color}
								mt={mt}
								justify={type === 'big' && 'flex-end'}
								className={
									filter.includes(item.month) || !filter.length
										? null
										: 'inactive'
								}
							>
								{item.month}
							</Subtitle>
						</Col>
					))}
				</Row>
			</>
		)
	}

	return (
		<Row gutter={type === 'event' ? '1' : '20'}>
			{courses.map((item, idx) => (
				<Col
					key={idx}
					sm={type === 'big' ? null : 6}
					onClick={() => handleCourse(item.id)}
				>
					<Subtitle
						color={item.background_color}
						mt={mt}
						justify={type === 'big' && 'flex-end'}
						className={
							filter.includes(item.id) || !filter.length ? null : 'inactive'
						}
					>
						{item.name}
					</Subtitle>
				</Col>
			))}
		</Row>
	)
}

export default Filter
