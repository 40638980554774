import { combineReducers } from 'redux'

import auth from './auth/reducer'
import availability from './availability/reducer'
import calendar from './calendar/reducer'
import checkout from './checkout/reducer'
import class_ from './class/reducer'
import contact from './contact/reducer'
import course from './course/reducer'
import feedback from './feedback/reducer'
import filter from './filter/reducer'
import glossary from './glossary/reducer'
import leads from './leads/reducer'
import modal from './modal/reducer'
import onboarding from './onboarding/reducer'
import payments from './payments/reducer'
import profile from './profile/reducer'
import reschedule from './reschedule/reducer'
import select from './select/reducer'
import students from './students/reducer'

export default combineReducers({
	auth,
	class_,
	calendar,
	course,
	profile,
	students,
	modal,
	feedback,
	filter,
	reschedule,
	availability,
	payments,
	contact,
	glossary,
	onboarding,
	select,
	checkout,
	leads,
})
