import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions(
	{
		setCheckoutStep: ['step'],
		getAddress: [],
		getZipcode: { zipcode: undefined },
		getCoupon: { coupon: undefined },
		setCoupon: { name: undefined, discount_percent: undefined },
		setCouponError: { error: undefined },
		setAddress: {
			address: undefined,
			city: undefined,
			state: undefined,
			country: undefined,
			zipcode: undefined,
			neighborhood: undefined,
			number: undefined,
			complementary: undefined,
		},
		setCreditCard: {
			card_number: undefined,
			card_name: undefined,
			card_validity: undefined,
			card_cvv: undefined,
			card_cpf_cnpj: undefined,
			option_payment: undefined,
			installments: undefined,
		},
		setCheckoutFormStep: ['checkoutStep'],
		setLoading: ['loading'],

		checkoutRequest: [],
		checkoutDone: [],
	},
	{ prefix: 'CHECKOUT_' }
)

export default Creators
