import 'dayjs/locale/pt-br'

import React from 'react'
import { useSelector } from 'react-redux'
import { Row } from 'react-simple-flex-grid'

import dayjs from 'dayjs'

import Box from '../Box'
import { BoxSubtitle, BoxTitle } from '../Box/style'
import Filter from '../Filter'
import Icon from '../Icon'
import Free from './Free'
import Mentor from './Mentor'
import Student from './Student'
import { ColContent, EmptyEvent, IconContainer } from './style'

export function checkDate(event, minutesToOpen, minutesToClose) {
	const currentDate = dayjs().locale('pt-br')
	const eventDate = dayjs(event.date || event).locale('pt-br')
	const text = `${eventDate.format('DD [de] MMMM, [às] HH[h]mm')}`

	const mentoringIsHappening =
		eventDate.diff(currentDate, 'minutes') <= minutesToOpen &&
		eventDate.diff(currentDate, 'minutes') >= -minutesToClose

	const mentoringInThePast =
		eventDate.diff(currentDate, 'minutes') < -minutesToClose

	return { eventDate, mentoringInThePast, mentoringIsHappening, text }
}

function Calendar() {
	const {
		profile: {
			profile: { selected_profile },
		},
		calendar: {
			studentSelectedCalendar,
			mentor: mentorCalendar,
			consultant: consultantCalendar,
		},
		course: {
			course: { type },
		},
		class_: { classSelected, liveClasses },
	} = useSelector((state) => state)
	const currentDay = dayjs().locale('pt-br')
	const students = []

	const filters = [
		{
			id: 'LIVE_CLASS',
			name: 'Live Classes',
		},
		{
			id: 'CONSULTING',
			name: 'Carreira',
		},
		{
			id: 'MENTORSHIP',
			name: 'Mentorias',
		},
		{
			id: 'OFFICE_HOURS',
			name: 'Office-hours',
		},
		{
			id: 'DEMO_DAY',
			name: 'Demo day',
		},
		{
			id: 'AWARI_TALKS',
			name: 'Awari talks',
		},
		{
			id: 'MONITORING',
			name: 'Monitoria',
		},
		{
			id: 'FREE',
			name: 'Live Classes',
		},
	]
	const liveClass = liveClasses?.find((d) => {
		const { mentoringInThePast } = checkDate(d, 15, 135)

		return !mentoringInThePast
	})

	return (
		<Box className='calendar'>
			{selected_profile === 'STUDENT' ? (
				<>
					<BoxTitle>CALENDÁRIO</BoxTitle>
					{type !== 'FREE' ? (
						<BoxSubtitle>Próximos eventos</BoxSubtitle>
					) : (
						<BoxSubtitle>Eventos</BoxSubtitle>
					)}
				</>
			) : (
				<>
					<Row align='middle'>
						<ColContent width='max-content' xs={{ span: 1 }} md={{ span: 2 }}>
							<IconContainer>
								<Icon name='calendar' width='16' height='18' fill='none' />
							</IconContainer>
						</ColContent>
						<ColContent xs={{ span: 11 }} md={{ span: 10 }}>
							<BoxSubtitle lh={1}>Próximos Eventos</BoxSubtitle>
						</ColContent>
					</Row>
					<Row gutter='15'>
						<ColContent width='max-content' xs={{ span: 1 }} md={{ span: 2 }}>
							<BoxSubtitle fs={24} lh={1.3} mt={15} colors='gray4'>
								{currentDay.format('DD')}
							</BoxSubtitle>
						</ColContent>
						<ColContent xs={{ span: 1 }} md={{ span: 2 }}>
							<BoxSubtitle fs={24} lh={1.3} mt={15}>
								{currentDay.format('MMMM')}
							</BoxSubtitle>
						</ColContent>
					</Row>
				</>
			)}

			{dayjs(classSelected?.class?.end_at)
				.locale('pt-br')
				.isBefore(dayjs().locale('pt-br')) ? (
				<></>
			) : (
				<Filter
					element='calendar__event'
					type='event'
					mt={16}
					items={
						selected_profile === 'STUDENT'
							? studentSelectedCalendar?.calendar
									.map((d) => d.type)
									.reduce((acc, val) => {
										if (!acc.includes(val)) {
											acc.push(val)
										}

										return acc
									}, [])
									.map((d) => filters.find((f) => f.id === d))
							: students
					}
				/>
			)}

			<>
				{(() => {
					if (selected_profile === 'STUDENT') {
						return (
							<>
								{dayjs(classSelected?.class?.end_at)
									.locale('pt-br')
									.isBefore(dayjs().locale('pt-br')) ||
								!studentSelectedCalendar?.calendar.length ? (
									<EmptyEvent>Não há próximos eventos agendados</EmptyEvent>
								) : (
									<>
										{studentSelectedCalendar?.calendar.map((agenda, idx) => {
											const { text, mentoringInThePast, mentoringIsHappening } =
												checkDate(
													agenda,
													(() => {
														switch (agenda.type) {
															case 'LIVE_CLASS': {
																return 15
															}
															case 'OFFICE_HOURS': {
																return 15
															}
															case 'DEMO_DAY': {
																return 15
															}
															case 'AWARI_TALKS': {
																return 15
															}
															case 'FREE': {
																return 15
															}
															default: {
																return 5
															}
														}
													})(),
													(() => {
														switch (agenda.type) {
															case 'LIVE_CLASS': {
																return 135
															}
															case 'OFFICE_HOURS': {
																return 75
															}
															case 'DEMO_DAY': {
																return 135
															}
															case 'AWARI_TALKS': {
																return 135
															}
															case 'FREE': {
																return 135
															}
															default: {
																return 30
															}
														}
													})()
												)

											return (
												agenda &&
												text !== 'Invalid Date' && (
													<>
														{type === 'FREE' &&
														agenda.type === 'FREE' &&
														mentoringInThePast ? (
															<Free
																key={idx}
																type={agenda.type}
																title={agenda.title && agenda.title}
																link={agenda.link && agenda.link}
																record_link={
																	agenda.record_link && agenda.record_link
																}
																mentoringInThePast={mentoringInThePast}
																mentoringIsHappening={mentoringIsHappening}
																eventDate={text}
															/>
														) : (
															<Student
																key={idx}
																type={agenda.type}
																title={agenda.title && agenda.title}
																link={agenda.link && agenda.link}
																externalLink={liveClass?.external_link}
																liveClassId={liveClass?.id}
																mentoringInThePast={mentoringInThePast}
																mentoringIsHappening={mentoringIsHappening}
																eventDate={text}
															/>
														)}
													</>
												)
											)
										})}
									</>
								)}
							</>
						)
					}

					if (selected_profile === 'MENTOR') {
						return (
							<>
								{mentorCalendar[0]?.id &&
									mentorCalendar?.map((agenda, idx) => {
										const { student, class: aula } = agenda.classRegistration
											? agenda.classRegistration
											: ''
										const { individual } = student || ''

										const { text, mentoringInThePast, mentoringIsHappening } =
											checkDate(agenda, 5, 30)

										return (
											<Mentor
												key={idx}
												aula={aula}
												individual={individual && individual}
												mentoringInThePast={mentoringInThePast}
												mentoringIsHappening={mentoringIsHappening}
												event={agenda}
												eventDate={text}
												date={agenda.date || agenda}
												type={agenda.type && agenda.type}
												title={agenda.title && agenda.title}
												link={agenda.link && agenda.link}
												classe={agenda.class && agenda.class}
											/>
										)
									})}
							</>
						)
					}

					return (
						<>
							{consultantCalendar[0]?.id &&
								consultantCalendar?.map((agenda, idx) => {
									const { student, class: aula } = agenda.classRegistration
										? agenda.classRegistration
										: ''
									const { individual } = student || ''

									const { text, mentoringInThePast, mentoringIsHappening } =
										checkDate(agenda, 5, 30)

									return (
										<Mentor
											key={idx}
											aula={aula}
											individual={individual && individual}
											mentoringInThePast={mentoringInThePast}
											mentoringIsHappening={mentoringIsHappening}
											event={agenda}
											eventDate={text}
											date={agenda.date || agenda}
											type={agenda.type && agenda.type}
											title={agenda.title && agenda.title}
											link={agenda.link && agenda.link}
											classe={agenda.class && agenda.class}
										/>
									)
								})}
						</>
					)
				})()}
			</>
		</Box>
	)
}

export default Calendar
