import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Types, Creators } from './actions'

export function* index() {
	try {
		const { data } = yield call(api.get, '/availabilities')

		yield put(Creators.availabilityIndexSuccess(data))
	} catch (response) {
		//
	}
}

export function* show({ id }) {
	try {
		const { data } = yield call(api.get, `/availabilities/${id}`)

		yield put(Creators.availabilityShowSuccess(data))
	} catch (response) {
		//
	}
}

export function* update({ availability_id, availabilityHours }) {
	try {
		yield call(api.put, `/availabilities/${availability_id}`, {
			availabilityHours,
		})

		yield put(Creators.availabilityUpdateSuccess())
		yield put(Creators.availabilityIndexRequest())
	} catch (response) {
		//
	}
}

export default all([
	takeLatest(Types.AVAILABILITY_INDEX_REQUEST, index),
	takeLatest(Types.AVAILABILITY_SHOW_REQUEST, show),
	takeLatest(Types.AVAILABILITY_UPDATE_REQUEST, update),
])
