import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	setMultipleSelect: { id: '', option: '', value: '', maxSelections: '' },
	resetMultipleSelect: [],

	setSingleSelect: { id: '', option: '', value: '' },
	resetSingleSelect: [],
})

export default Creators
