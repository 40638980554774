import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as ModalCreators } from '../modal/actions'
import { Types, Creators } from './actions'

export function* store({ name, email, comment, course, subject, profile }) {
	try {
		yield call(api.post, 'contact', {
			name,
			email,
			comment,
			course,
			subject,
			profile,
		})

		yield put(Creators.contactStoreSuccess())
		yield put(
			ModalCreators.modalShowSuccess(
				'Sucesso!',
				'Agradecemos o contato e responderemos o mais breve possível.',
				undefined,
				true,
				false
			)
		)
	} catch ({ response }) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export default all([takeLatest(Types.CONTACT_STORE_REQUEST, store)])
