import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as CalendarCreators } from '../calendar/actions'
import { Creators as ClassCreators } from '../class/actions'
import { Creators as CourseCreators } from '../course/actions'
import { Creators as FeedbackCreators } from '../feedback/actions'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators as PaymentsCreators } from '../payments/actions'
import { Creators as StudentsCreators } from '../students/actions'
import { Creators, Types } from './actions'

export function* show() {
	try {
		const { data } = yield call(api.get, 'profile')

		yield put(Creators.profileShowSuccess(data))
		yield put(FeedbackCreators.feedbackReset())

		if (data.selected_profile === 'LEAD') {
			yield put(CourseCreators.coursesBelemniteShowRequest())
		} else if (data.selected_profile === 'STUDENT') {
			yield put(ClassCreators.classShowRequest())
			yield put(
				ClassCreators.classSelectRequest(
					data?.student?.selected_class_registration,
					true
				)
			)
			yield put(CourseCreators.coursesBelemniteShowRequest())
			if (data.student.show_popup) {
				yield put(ModalCreators.modalShowOnboarding('studentReferrals'))
			}
		} else if (data.selected_profile === 'MENTOR') {
			yield put(ClassCreators.classReset())
			yield put(CalendarCreators.calendarMentorShowRequest())
			yield put(StudentsCreators.studentsMentorShowRequest())
			yield put(PaymentsCreators.paymentsMentorShowRequest())
			yield put(PaymentsCreators.paymentsAccountPixGet())
			if (data.mentor.show_popup) {
				yield put(ModalCreators.modalShowOnboarding('mentorReferrals'))
			}
		} else if (data.selected_profile === 'CONSULTANT') {
			yield put(ClassCreators.classReset())
			yield put(CalendarCreators.calendarConsultantShowRequest())
			yield put(StudentsCreators.studentsConsultantShowRequest())
			yield put(PaymentsCreators.paymentsConsultantShowRequest())
			yield put(PaymentsCreators.paymentsAccountPixGet())
			if (data.consultant.show_popup) {
				yield put(ModalCreators.modalShowOnboarding('mentorReferrals'))
			}
		} else if (data.selected_profile === 'INSTRUCTOR') {
			yield put(ClassCreators.classReset())
			yield put(CalendarCreators.calendarInstructorShowRequest())
			yield put(StudentsCreators.studentsInstructorShowRequest())
		}
	} catch (response) {
		//
	}
}

export function* update({
	name,
	last_name,
	linkedin,
	country,
	city,
	trajectory,
	company_name,
	company_role,
	goals,
	bio,
}) {
	try {
		yield call(api.put, 'profile', {
			name,
			last_name,
			linkedin,
			country,
			city,
			trajectory,
			company_name,
			company_role,
			goals,
			bio,
		})

		yield put(Creators.profileStoreSuccess())
		yield put(Creators.profileShowRequest())
		yield put(Creators.profileStoreDone())
	} catch ({ response }) {
		yield put(
			Creators.profileStoreError(response.data.message || 'Ocorreu um erro')
		)
		yield put(Creators.profileStoreDone())
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* updatePicture({ avatar }) {
	try {
		const data = new FormData()

		avatar && data.append('avatar', avatar)

		const response = yield call(api.put, 'profile/picture', data)

		yield put(
			Creators.profileUpdatePictureSuccess(response.data.profile_picture)
		)
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* updateCompany({ avatarCompany }) {
	try {
		const data = new FormData()

		avatarCompany && data.append('avatar', avatarCompany)
		if (avatarCompany?.name) {
			yield call(api.put, 'profile/company/picture', data)
			yield put(Creators.profileUpdateCompanySuccess())
			yield put(Creators.profileShowRequest())
		}
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* progressOnboarding() {
	try {
		const {
			profile: {
				profile: { mentor, consultant, selected_profile },
			},
			modal: { modalTitleOnboarding },
		} = yield select((state) => state)

		yield call(api.post, `onboarding`, {
			status:
				selected_profile === 'MENTOR'
					? mentor?.onboarding_status
					: consultant?.onboarding_status,
		})
		yield put(Creators.progressOnboardingSuccess())
		if (!modalTitleOnboarding) {
			yield put(Creators.profileShowRequest())
		}
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* progressOnboardingStudent() {
	try {
		const {
			class_: {
				classSelected: { id, onboarding_status },
			},
		} = yield select((state) => state)

		yield call(api.post, `class_registrations/${id}/onboarding`, {
			status: onboarding_status,
		})
		yield put(Creators.progressOnboardingStudentSuccess())
		yield put(ClassCreators.classSelectRequest(id, true))
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Tente novamente mais tarde, ou entre em contato com suporte@awari.com.br.'
			)
		)
	}
}

export function* selectProfile({ profile }) {
	try {
		yield call(api.post, 'profile/select', {
			profile,
		})

		yield put(Creators.profileSelectSuccess())
	} catch ({ response }) {
		//
		yield put(Creators.profileSelectError(response.data.message))
	}
}

export function* referralPopup({ selected_profile }) {
	try {
		yield call(api.post, 'referral', {
			profile: selected_profile,
		})
	} catch ({ response }) {
		//
	}
}

export default all([
	takeLatest(Types.PROFILE_SHOW_REQUEST, show),
	takeLatest(Types.PROFILE_UPDATE_REQUEST, update),
	takeLatest(Types.PROFILE_UPDATE_PICTURE_REQUEST, updatePicture),
	takeLatest(Types.PROFILE_UPDATE_COMPANY_REQUEST, updateCompany),
	takeLatest(Types.PROFILE_SELECT_REQUEST, selectProfile),
	takeLatest(Types.PROFILE_SELECT_SUCCESS, show),
	takeLatest(Types.PROGRESS_ONBOARDING_REQUEST, progressOnboarding),
	takeLatest(
		Types.PROGRESS_ONBOARDING_STUDENT_REQUEST,
		progressOnboardingStudent
	),
	takeLatest(Types.REFERRAL_POPUP, referralPopup),
])
