import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

const individualModel = {
	id: undefined,
	name: undefined,
	last_name: undefined,
	profile_picture: undefined,
}

const mentorModel = {
	id: undefined,
	student_id: undefined,
	mentor_id: undefined,
	student: {
		id: undefined,
		individual_id: undefined,
		individual: individualModel,
	},
	mentorships: [
		{
			id: undefined,
			class_registration_id: undefined,
			mentorFeedback: {
				id: undefined,
				mentorship_id: undefined,
				rating: undefined,
				feedback: undefined,
				presence: undefined,
				reason: undefined,
				created_at: undefined,
				updated_at: undefined,
			},
			completed: undefined,
			presence: undefined,
		},
	],
	class: {
		id: undefined,
		course_id: undefined,
		name: undefined,
		cohort: undefined,
		number_mentorships: undefined,
		number_consultings: undefined,
		number_live_classes: undefined,
		course: {
			id: undefined,
			name: undefined,
			background_color: undefined,
		},
	},
	tasks: [
		{
			unit_id: undefined,
			topic_title: undefined,
			class_registration_id: undefined,
			progress_id: undefined,
			completed: undefined,
			status: undefined,
			links: [
				{
					id: undefined,
					progress_id: undefined,
					link: undefined,
					title: undefined,
					created_at: undefined,
					updated_at: undefined,
				},
			],
		},
	],
	units: [
		{
			id: undefined,
			title: undefined,
			order: undefined,
		},
	],
	progress: undefined,
}

const consultantModel = {
	id: undefined,
	student_id: undefined,
	consultant_id: undefined,
	student: {
		id: undefined,
		individual_id: undefined,
		individual: individualModel,
	},
	consultings: [
		{
			id: undefined,
			class_registration_id: undefined,
			consultantFeedback: {
				id: undefined,
				consulting_id: undefined,
				rating: undefined,
				feedback: undefined,
				presence: undefined,
				reason: undefined,
				created_at: undefined,
				updated_at: undefined,
			},
			completed: undefined,
			presence: undefined,
		},
	],
	class: {
		id: undefined,
		course_id: undefined,
		name: undefined,
		cohort: undefined,
		number_mentorships: undefined,
		number_consultings: undefined,
		number_live_classes: undefined,
		course: {
			id: undefined,
			name: undefined,
			background_color: undefined,
		},
	},
	tasks: [
		{
			unit_id: undefined,
			topic_title: undefined,
			class_registration_id: undefined,
			progress_id: undefined,
			completed: undefined,
			status: undefined,
			links: [
				{
					id: undefined,
					progress_id: undefined,
					link: undefined,
					title: undefined,
					created_at: undefined,
					updated_at: undefined,
				},
			],
		},
	],
	units: [
		{
			id: undefined,
			title: undefined,
			order: undefined,
		},
	],
	progress: undefined,
}

const instructorModel = {
	class: {
		course: {
			background_color: '#ffffff',
		},
		classRegistrations: [
			{
				student: {
					individual: {
						profile_picture: undefined,
					},
				},
			},
		],
	},
}

export const INITIAL_STATE = Immutable({
	loading: false,
	mentor: [mentorModel],
	consultant: [consultantModel],
	instructor: [instructorModel],
	modalCourses: false,
	modalProfile: false,
	modalProfileContent: false,
	downloadSocialShare: undefined,
	howMet: undefined,
})

export default createReducer(INITIAL_STATE, {
	[Types.STUDENTS_MENTOR_SHOW_REQUEST]: (state) => state.set('loading', true),
	[Types.STUDENTS_MENTOR_SHOW_SUCCESS]: (state, { students }) =>
		state.merge({ mentor: students, loading: false }),

	[Types.STUDENTS_CONSULTANT_SHOW_REQUEST]: (state) =>
		state.set('loading', true),
	[Types.STUDENTS_CONSULTANT_SHOW_SUCCESS]: (state, { students }) =>
		state.merge({ consultant: students, loading: false }),

	[Types.STUDENT_APLICATION_SHOW_REQUEST]: (state) =>
		state.set('loading', true),
	[Types.STUDENT_APLICATION_SHOW_SUCESS]: (state) =>
		state.merge({ loading: false }),

	[Types.STUDENTS_INSTRUCTOR_SHOW_REQUEST]: (state) =>
		state.set('loading', true),
	[Types.STUDENTS_INSTRUCTOR_SHOW_SUCCESS]: (state, { students }) =>
		state.merge({ instructor: students, loading: false }),

	[Types.MODAL_COURSES]: (state, { status }) =>
		state.merge({
			modalCourses: status,
		}),
	[Types.MODAL_PROFILE]: (state, { status }) =>
		state.merge({
			modalProfile: status,
		}),
	[Types.MODAL_PROFILE_CONTENT]: (state, { status }) =>
		state.merge({
			modalProfileContent: status,
			modalProfile: true,
		}),

	[Types.DOWNLOAD_SOCIAL_SHARE]: (state, { name }) =>
		state.merge({
			downloadSocialShare: name,
		}),

	[Types.STUDENT_SET_HOW_MET]: (state, { howMet }) => state.merge({ howMet }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
