import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	calendarStudentShowRequest: [],
	calendarFullStudentShowRequest: [],
	calendarStudentShowSuccess: ['calendar'],
	calendarFullStudentShowSuccess: ['calendarFull'],
	calendarStudentSelect: ['class_registration_id'],
	calendarFullStudentSelect: ['class_registration_id'],
	calendarStudentEventsSet: ['events', 'nextEvent'],
	calendarFullStudentEventsSet: ['events'],

	calendarMentorShowRequest: [],
	calendarMentorShowSuccess: ['calendar'],

	calendarConsultantShowRequest: [],
	calendarConsultantShowSuccess: ['calendar'],

	calendarInstructorShowRequest: [],
	calendarInstructorShowSuccess: ['calendar'],

	nextClassShow: ['nextClass'],
	nextMentorshipShow: ['nextMentorship'],
	nextConsultingShow: ['nextConsulting'],

	calendarFullNavigation: ['fullNavigation'],
})

export default Creators
