import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
	feedbackReset: [],
	feedbackShow: ['feedback'],

	feedbacksModalRequest: [],
	feedbacksModalDone: [],
	feedbacksModalSuccess: ['feedbacksModal'],

	feedbackRequest: [],
	feedbackDone: [],
	feedbackError: [],

	feedbackTasksShow: ['feedbackTasks', 'classRegistrationId'],

	feedbackTaskUpdate: ['feedbackTask'],
	feedbackTaskDone: [],
})

export default Creators
