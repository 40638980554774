import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Legacy from '../views/Legacy'

function RouterApp() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path='/' component={Legacy} />
				<Redirect to='/' />
			</Switch>
		</BrowserRouter>
	)
}
export default RouterApp
