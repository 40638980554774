import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	loading: false,
})

export default createReducer(INITIAL_STATE, {
	[Types.CONTACT_STORE_REQUEST]: (state) => state.merge({ loading: true }),
	[Types.CONTACT_STORE_SUCCESS]: (state) =>
		state.merge({
			loading: false,
		}),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
