import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

const availabilitiesModel = {
	id: undefined,
	day: undefined,
	mentor_id: undefined,
	availabilityHours: [
		{
			id: undefined,
			availability_id: undefined,
			begin: undefined,
			end: undefined,
		},
	],
}

export const INITIAL_STATE = Immutable({
	loading: false,
	availabilities: [availabilitiesModel],
	availabilitiesShow: [availabilitiesModel],
})

export default createReducer(INITIAL_STATE, {
	[Types.AVAILABILITY_INDEX_REQUEST]: (state) => state.merge({ loading: true }),
	[Types.AVAILABILITY_INDEX_SUCCESS]: (state, { availabilities }) =>
		state.merge({ availabilities, loading: false }),

	[Types.AVAILABILITY_SHOW_REQUEST]: (state) => state.merge({ loading: true }),
	[Types.AVAILABILITY_SHOW_SUCCESS]: (state, { availabilities }) =>
		state.merge({ availabilitiesShow: availabilities, loading: false }),

	[Types.AVAILABILITY_UPDATE_REQUEST]: (state) =>
		state.merge({ loading: true }),
	[Types.AVAILABILITY_UPDATE_SUCCESS]: (state) =>
		state.merge({ loading: false }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
