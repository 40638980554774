import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const StyledLegacy = styled.div`
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> svg {
		margin-top: 60px;
	}
`

export const StyledLegacyBox = styled.div`
	${({ theme }) => css`
		margin: 60px auto;
		background-color: ${theme.colors.white};
		padding: 44px 32px 0px;
		border-radius: 8px;
		height: 100%;
		width: 95%;
		max-width: 500px;
		max-height: 430px;
		box-shadow: 0px 4px 10px rgba(48, 44, 80, 0.1);

		display: flex;
		justify-content: center;

		.desktop {
			display: none;
		}

		@media (min-width: ${theme.breakpoints[1]}px) {
			max-height: 370px;

			.mobile {
				display: none;
			}

			.desktop {
				display: inline;
				margin-right: 24px;
			}
		}
	`}
`

export const StyledLegacyTitle = styled.p`
	font-family: 'BasisGrotesquePro Bold';
	font-size: 36px;
	line-height: 108%;
	color: #302c50;
	margin-bottom: 12px;
`

export const StyledLegacyText = styled.p`
	font-family: 'BasisGrotesquePro';
	font-weight: 300;
	font-size: 16px;
	line-height: 155%;
	color: #8493ac;

	margin-bottom: 24px;
`

export const StyledLegacyButton = styled.a`
	${({ theme }) => css`
		background: ${theme.colors.primary};
		border-radius: 100px;
		max-width: 292px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: 'BasisGrotesquePro Bold';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 140%;
		color: ${theme.colors.white};

		margin: 0 auto;
	`}
`
