import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators, Types } from './actions'

export function* showCourse({ id }) {
	try {
		const { data } = yield call(api.get, `courses/${id}`)

		yield put(Creators.courseShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* showCoursePreview({ id }) {
	try {
		const { data } = yield call(api.get, `courses/${id}`)

		yield put(Creators.coursePreviewShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* showCoursesBelemnite() {
	try {
		const { data } = yield call(api.get, `/courses`)

		yield put(Creators.coursesBelemniteShowSuccess(data))
	} catch ({ response }) {
		//
	}
}

export function* showTopic({ id }) {
	try {
		const { data } = yield call(api.get, `topics/${id}`)

		yield put(Creators.topicShowSuccess(data))
	} catch ({ response }) {
		yield put(Creators.topicShowFailure(response.data))
	}
}

export function* showTopicPreview({ id }) {
	try {
		const { data } = yield call(api.get, `topics/${id}`)

		yield put(Creators.topicPreviewShowSuccess(data))
	} catch ({ response }) {
		yield put(Creators.topicPreviewShowFailure(response.data))
	}
}

export default all([
	takeLatest(Types.COURSE_SHOW_REQUEST, showCourse),
	takeLatest(Types.COURSE_PREVIEW_SHOW_REQUEST, showCoursePreview),
	takeLatest(Types.COURSES_BELEMNITE_SHOW_REQUEST, showCoursesBelemnite),
	takeLatest(Types.TOPIC_SHOW_REQUEST, showTopic),
	takeLatest(Types.TOPIC_PREVIEW_SHOW_REQUEST, showTopicPreview),
])
