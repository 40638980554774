import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types as TypeAuth } from '../auth/actions'
import { Types } from './actions'

export const INITIAL_STATE = Immutable({
	group: Math.floor(Math.random() * 3) + 1,
	step: 1,
	loading: false,
	matchCompleted: false,
	data: {
		goals: [],
		companies: [],
		expertises: [],
		profile: [],
		availability: [],
	},
	dataMatchMentor: {
		mentor_id: undefined,
		day_mentorship: undefined,
		hour_mentorship: undefined,
	},
})

export default createReducer(INITIAL_STATE, {
	[Types.NEXT_STEP]: (state, { step }) => state.merge({ step }),

	[Types.SET_GOALS]: (state, { goals }) =>
		state.merge({ data: { ...state.data, goals } }),
	[Types.SET_EXPERTISES]: (state, { expertises }) =>
		state.merge({ data: { ...state.data, expertises } }),
	[Types.SET_COMPANIES]: (state, { companies }) =>
		state.merge({ data: { ...state.data, companies } }),
	[Types.SET_PROFILE]: (state, { profile }) =>
		state.merge({ data: { ...state.data, profile } }),
	[Types.SET_AVAILABILITY]: (state, { availability }) =>
		state.merge({ data: { ...state.data, availability } }),

	[Types.ONBOARDING_QUESTIONS]: (state) => state.merge({ loading: true }),
	[Types.ONBOARDING_QUESTIONS_SUCCESS]: (state) =>
		state.merge({ loading: false }),

	[Types.SET_MENTOR_ID]: (state, { mentor_id }) =>
		state.merge({ dataMatchMentor: { ...state.dataMatchMentor, mentor_id } }),
	[Types.SET_DAY_MENTORSHIP]: (state, { day_mentorship }) =>
		state.merge({
			dataMatchMentor: { ...state.dataMatchMentor, day_mentorship },
		}),
	[Types.SET_HOUR_MENTORSHIP]: (state, { hour_mentorship }) =>
		state.merge({
			dataMatchMentor: { ...state.dataMatchMentor, hour_mentorship },
		}),

	[Types.ONBOARDING_CHOOSE_MENTOR]: (state) => state.merge({ loading: true }),
	[Types.ONBOARDING_CHOOSE_MENTOR_SUCCESS]: (state) =>
		state.merge({ loading: false, matchCompleted: true }),

	[TypeAuth.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
})
