import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import api from '../../../utils/api'
import { Creators as ModalCreators } from '../modal/actions'
import { Creators, Types } from './actions'

export function* sendIndication() {
	try {
		const {
			leads: { friends },
		} = yield select((state) => state)

		yield call(api.post, `indications`, { friends })
		yield put(Creators.resetIndicacoes())

		yield put(
			ModalCreators.modalShowSuccess(
				'Sucesso!',
				'Convite enviado.',
				undefined,
				false,
				false
			)
		)
	} catch (response) {
		yield put(
			ModalCreators.modalShowError(
				'Ops! Parece que algo deu errado :(',
				'Um erro ocorreu! Tente novamente mais tarde, ou entre em contato com time@awari.com.br e vamos te ajudar.'
			)
		)
	}
}

export default all([takeLatest(Types.SEND_INDICACAO, sendIndication)])
